import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExternalUsersService } from 'src/app/Services/external-users/external-users.service';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-external-users',
  templateUrl: './external-users.component.html',
  styleUrls: ['./external-users.component.css']
})
export class ExternalUsersComponent implements OnInit {

  users = [];
  options = [];
  selected: any;

  messages = 'notify';

  isLoadMore = true;
  page = 1;
  size = 100;
  load = false;
  ismanage = false;

  constructor(
    private extUserService: ExternalUsersService,
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private cookieService: CookieService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.ExternalUsers.Manage')
          || perm.includes('USM.Administration.Full')) {
          this.ismanage = true;
      }
    }
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DELETE').subscribe((resp3: string) => {
        if (this.ismanage === true) {
          this.options = [
            { label: resp2, value: 'Edit' },
            { label: resp3, value: 'Delete' }
          ];
        }
      });
    });

    this.getExternalUsers();
   }

  ngOnInit() {
    localStorage.removeItem('external-user');
  }

  gotoCreateExternalUser() {
    this.router.navigateByUrl('createexternaluser');
  }

  getExternalUsers() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.extUserService.getExternalUsersPageSize(this.page, this.size).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].options = this.options;
          this.users.push(res[i]);
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    }, err => {
      this.handleError(err);
    });
  }

  saveExternalUser(event, user) {
    localStorage.setItem('external-user', JSON.stringify(user));
    if (event.value === 'Edit') {
      this.router.navigateByUrl('/externalusers/' + user.externalUserId);
    } else if (event.value === 'Delete') {
      this.load = true;
      // tslint:disable-next-line: deprecation
      this.extUserService.deleteExternalUser(user.externalUserId).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DELETED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.users = [];
          this.getExternalUsers();
        });
      });
    }
  }

  loadMore() {
    this.page += 1;
    this.getExternalUsers();
  }

  handleError(err) {
    this.load = false;
  }

}
