import { RolesService } from './../../Services/roles/roles.service';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { DataCache } from 'src/app/Helpers/data-cache';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css']
})
export class GroupDetailComponent implements OnInit {

  group: any;
  roles = [];
  roless = [];

  loading = false;

  gps = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private cookieService: CookieService,
    private userroleService: RolesService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.group = JSON.parse(localStorage.getItem('group'));
   }

  async ngOnInit() {
    await this.getGroups();

    if (this.group.typeRoleGroups !== null && this.group.typeRoleGroups.length > 0) {
      for (const val of this.group.typeRoleGroups) {
        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
            }
          }
        }
      }
    }

    this.loadRoles();
  }

  loadRoles() {
    this.userroleService.getUserRoles(0, this.group.groupId).subscribe((resp) => {
      if (resp != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < resp.length; j++) {
          this.translate.get('GENERAL.EXPERT').subscribe((trans1) => {
            this.translate.get('GENERAL.USER').subscribe((trans2) => {
              this.translate.get('GENERAL.ADMIN').subscribe((trans3) => {
                this.translate.get('GENERAL.PANELS').subscribe((trans4) => {
                  this.translate.get('GENERAL.TASKS').subscribe((trans5) => {
                    this.translate.get('GENERAL.INFORMATION').subscribe((trans6) => {
                      this.translate.get('GENERAL.GENERAL').subscribe((trans7) => {
                        this.translate.get('GENERAL.ADMINISTRATION').subscribe((trans8) => {
                          if (resp[j].roleName === 'ADMIN') {
                            resp[j].roleName = trans3;
                          } else if (resp[j].roleName === 'USER') {
                            resp[j].roleName = trans2;
                          } else if (resp[j].roleName === 'EXPERT') {
                            resp[j].roleName = trans1;
                          }

                          if (resp[j].objectType === 'GENERAL') {
                            resp[j].objectType = trans7;
                          }

                          if (resp[j].objectType === 'TASKS') {
                            resp[j].objectType = trans5;
                          } else if (resp[j].objectType === 'INFORMATION') {
                            resp[j].objectType = trans6;
                          } else if (resp[j].objectType === 'ADMINISTRATION') {
                            resp[j].objectType = trans8;
                          } else if (resp[j].objectType === 'PANELS') {
                            resp[j].objectType = trans4;
                          }

                          const role = resp[j].roleName + ': ' + resp[j].module + '/' + resp[j].objectType + '/' +
                          resp[j].objectId;
                          if (!this.roless.includes(role)) {
                            this.roles.push(resp[j]);
                            this.roless.push(role);
                          }
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        }
      }
    });
  }

  async getGroups() {
    this.loading = true;
    const data = await DataCache.groupdata$.toPromise();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
      }
    }
    this.loading = false;
  }

  load() {}

}
