import { KeyValue } from 'src/app/Models/KeyValue';
import { ExtUserUpdater } from './../../Models/KeyValue';
import { Component, OnInit } from '@angular/core';
import { ExternalUsers } from 'src/app/Models/ExternalUser';
import { ExternalUseridTypesService } from 'src/app/Services/external-userid-types/external-userid-types.service';
import { Router } from '@angular/router';
import { ExternalUsersService } from 'src/app/Services/external-users/external-users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from 'src/app/Services/users/users.service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-edit-external-user',
  templateUrl: './edit-external-user.component.html',
  styleUrls: ['./edit-external-user.component.css']
})
export class EditExternalUserComponent implements OnInit {

  users = [];
  user: any;

  idtypes = [];
  idtype: any;

  ExternalId: string;
  OldEId: string;

  loading = false;

  extUser: any;

  constructor(
    private extIds: ExternalUseridTypesService,
    private router: Router,
    private extUserService: ExternalUsersService,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private userService: UsersService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.extUser = JSON.parse(localStorage.getItem('external-user'));

    this.ExternalId = this.extUser.externalUserId;
    this.OldEId = this.extUser.externalUserId;

    this.getExternalIds();
    this.getUsers();
   }

  ngOnInit() {
  }

  getUsers() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.userService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.users.push({label: res[i].firstName + ' ' + res[i].lastName, value: res[i]});
        }
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.users.length; j++) {
          if (this.users[j].value.userId === this.extUser.userId) {
            this.user = this.users[j].value;
          }
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  getExternalIds() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.extIds.getExternalUserIdTypes().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.idtypes.push({label: res[i].externalIdType, value: res[i]});
        }
        // tslint:disable-next-line: prefer-for-of
        for (let j = 0; j < this.idtypes.length; j++) {
          if (this.idtypes[j].value.externalIdType === this.extUser.externalUserIdType) {
            this.idtype = this.idtypes[j].value;
          }
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  onSave() {
    if (this.user === undefined || this.user === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTUSER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.idtype === undefined || this.idtype === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTIDTYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.ExternalId === undefined || this.ExternalId === null || this.ExternalId.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREXTERNALID').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else {
      const ext = new ExtUserUpdater();
      ext.ExtId = this.OldEId;
      ext.Values = [];

      /*const uid = new KeyValue();
      uid.key = 'uid';
      uid.Value = this.user.userId;
      ext.Values.push(uid);

      const exttype = new KeyValue();
      exttype.key = 'ext_type';
      exttype.Value = this.idtype.externalIdType;
      ext.Values.push(exttype);*/

      const extid = new KeyValue();
      extid.key = 'ext_id';
      extid.Value = this.ExternalId;
      ext.Values.push(extid);

      this.loading = true;
    // tslint:disable-next-line: deprecation
      this.extUserService.putExternalUser(ext.ExtId, ext).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EXTERNALUSERUPDATED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.loading = false;
          this.location.back();
        });
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
  }

}
