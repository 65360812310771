import { Department } from './../../Models/Department';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DepartmentUpdater } from 'src/app/Models/KeyValue';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.orgId = +oi;
  }

getDepartments() {
  const v = new Variables();
  const val = localStorage.getItem('oi');
  const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
  this.orgId = +oi;
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/departments/' + this.orgId;
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

getDepartmentsPageSize(page: number, size: number) {
  const v = new Variables();
  const val = localStorage.getItem('oi');
  const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
  this.orgId = +oi;
  // tslint:disable-next-line: max-line-length
  const url = this.apiUrl + '/api/departments/' + this.orgId + '/' + page + '/' + size;
  return this.httpclient.get<any>(url)
  .pipe(
    tap( // Log the result or error
      error => this.handleError
    )
  );
}

postDepartment(dept: Department): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.post(this.apiUrl + '/api/departments', dept, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

putDepartment(id: number, upt: DepartmentUpdater): Observable<any> {
  // const body = JSON.stringify(inst);
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.put(this.apiUrl + '/api/departments/' + id, upt, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

deleteDepartment(id: number): Observable<any> {
  const options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.httpclient.delete<any>(this.apiUrl + '/api/departments/' + id, options)
                  .pipe(
                    catchError(this.handleError)
                  );
}

private handleError(error: any) {
  console.error('error', error);
  throw error;
  return error;
}

}
