import { RolesService } from 'src/app/Services/roles/roles.service';
import { Department } from './../../Models/Department';
import { User } from 'src/app/Models/User';
import { DepartmentsService } from 'src/app/Services/departments/departments.service';
import { Organisation } from './../../Models/Organisation';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { OrganisationsService } from 'src/app/Services/organisations/organisations.service';
import * as CryptoJS from 'crypto-js';
import { UsersService } from 'src/app/Services/users/users.service';
import { UserRoles } from 'src/app/Models/UserRoles';

@Component({
  selector: 'app-create-organisation',
  templateUrl: './create-organisation.component.html',
  styleUrls: ['./create-organisation.component.css']
})
export class CreateOrganisationComponent implements OnInit {

  OrgName: string;
  OrgDesc: string;

  Admin: string;
  EmpId: number;
  Email: string;
  Password: string;
  ConfirmPassword: string;
  Pin: string;
  ConfirmPin: string;

  loading = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private orgService: OrganisationsService,
    private userService: UsersService,
    private deptService: DepartmentsService,
    private roleService: RolesService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
   }

  ngOnInit() {
  }

  onCreate() {
    this.loading = true;
    if (this.OrgName === undefined || this.OrgName === null || this.OrgName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERORGANISATIONNAME').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.OrgDesc === undefined || this.OrgDesc === null || this.OrgDesc.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERORGANISATIONDESCRIPTION').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.Admin === undefined || this.Admin === null || this.Admin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEFAULTADMINUSER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.EmpId === undefined || this.EmpId.toString().trim() === null
              || this.EmpId === 0 || this.EmpId.toString().trim() === '') {
                // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERADMINISTRATOREMPLOYEEID').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
     } else if (isNaN(this.EmpId)) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREMPLOYEEIDMUSTBENUMBER').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
     } else if (this.Email === undefined || this.Email === null || this.Email.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERADMINISTRATOREMAIL').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (!this.Email.match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$')) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.INVALIDEMAIL').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.Password === undefined || this.Password === null || this.Password.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERADMINISTRATORPASSWORD').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.ConfirmPassword === undefined || this.ConfirmPassword === null || this.ConfirmPassword.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPASSWORDCONFIRMATION').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.Password !== this.ConfirmPassword) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PASSWORDSDONOTMATCH').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.Pin === undefined || this.Pin === null || this.Pin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERADMINISTRATORPIN').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.ConfirmPin === undefined || this.ConfirmPin === null || this.ConfirmPin.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPINCONFIRMATION').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else if (this.Pin !== this.ConfirmPin) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PINSDONOTMATCH').subscribe((resp: string) => {
        this.confirm(resp);
        this.loading = false;
      });
    } else {
      const org = new Organisation();
      org.OrganisationName = this.OrgName;
      org.OrganisationDescription = this.OrgDesc;
      org.AdminName = this.Admin;
      org.AdminEmployeeId = this.EmpId;
      org.AdminEmail = this.Email;
      org.AdminPassword = this.Password;
      org.AdminPin = this.Pin;
      // tslint:disable-next-line: deprecation
      this.orgService.postOrganisation(org).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ORGANISATIONCREATED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.loading = false;
          this.location.back();
        });
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_orga_org_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ORGANISATIONALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Employee id already exists') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMPLOYEEIDALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }

    if (err.error.Message === 'Email already exists') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EMAILALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
