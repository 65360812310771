export class UserRoles {
    public roleId: number;
    public roleName: string;
    public roleModule: string;
    public userId: number;
    public groupId: number;
    public firstName: string;
    public lastName: string;
    public groupName: string;
    public orgId: string;
    public roleParentType: string;
    public parentId: string;
    public userGroupType: number;
    public expiry: string;
}