import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService implements CanActivate {

  constructor() { }

  canActivate() {
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      return false;
    }
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);
    // if (perm.includes('USM.Section.Organisation')
    //     || perm.includes('USM.Administration.Full')) {
    if (perm.includes('USM.Administration.Full')) {
          return true;
    } else {
      const org = localStorage.getItem('o');
      if (org === null) {
        return false;
      }
      const o = CryptoJS.AES.decrypt(org, v.pass).toString(CryptoJS.enc.Utf8);
      if (o === 'true') {
        return true;
      } else {
        return false;
      }
    }

    // const org = localStorage.getItem('o');
    // if (org === null) {
    //   return false;
    // }
    // const o = CryptoJS.AES.decrypt(org, v.pass).toString(CryptoJS.enc.Utf8);
    // if (o === 'true') {
    //   return true;
    // } else {
    //   return false;
    // }
  }

}
