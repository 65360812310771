import { catchError } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { GroupsService } from './../../Services/groups/groups.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';
import { DataCache } from 'src/app/Helpers/data-cache';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  groups = [];
  options = [];

  isLoadMore = true;
  page = 1;
  size = 100;
  load = false;
  ismanage = false;

  isadm = false;

  constructor(
    private groupService: GroupsService,
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Groups.Manage')
          || perm.includes('USM.Administration.Full')) {
          this.ismanage = true;
      }

      if (perm.includes('USM.Administration.Full')) {
          this.isadm = true;
      }
    }

    this.getGroups();
   }

  ngOnInit() {
    localStorage.removeItem('group');
  }

  gotoCreateGroup() {
    this.router.navigateByUrl('creategroup');
  }

  getGroups() {
    // this.groups = [];
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.groupService.getGroupsPageSize(this.page, this.size).subscribe((res) => {
      if (res != null) {
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          let isedit = false;
          if (res[i].typeRoleGroups !== null && res[i].typeRoleGroups.length > 0) {
            for (const val of res[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
                isedit = true;
              }
            }
          }
          this.options = [];

          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EDIT').subscribe((edit: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe((del: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.GROUP').subscribe((groupusers: string) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.DETAILS').subscribe((details: string) => {
                  if (isedit === true || this.isadm === true) {
                    this.options.push({ label: edit, value: 'Edit' });
                    this.options.push({ label: groupusers, value: 'GroupUsers' });
                  }

                  if (this.isadm === true) {
                    this.options.push({ label: del, value: 'Delete' });
                  }

                  this.options.push({ label: details, value: 'Details' });
                  res[i].options = this.options;
                  this.groups.push(res[i]);
                });
              });
            });
          });
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    }, err => {
      this.handleError(err);
    });
  }

  saveGroup(event, group) {
    localStorage.setItem('group', JSON.stringify(group));
    if (event.value === 'Edit') {
      this.router.navigateByUrl('/groups/' + group.groupId + '/edit');
    } else if (event.value === 'Delete') {
      this.load = true;
      // tslint:disable-next-line: deprecation
      this.groupService.deleteGroup(group.groupId).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DELETED').subscribe((res) => {
          this.notificationSvc.info('', res, 2000);
          this.groups = [];
          this.getGroups();
          DataCache.loadData(this.http);
        });
      }, err => {
        this.handleError(err);
      });
    } else if (event.value === 'GroupUsers') {
      this.router.navigateByUrl('/groups/' + group.groupId + '/groupusers');
    } else if (event.value === 'Details') {
      this.router.navigateByUrl('/groups/' + group.groupId);
    }
  }

  loadMore() {
    this.page += 1;
    this.getGroups();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    if (err.error.Message === 'Users exist in the group') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.GROUPHASUSERS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    }
  }
}
