import { Rol, Roles } from './../../Models/Roles';
import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserRoles } from 'src/app/Models/UserRoles';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
    this.apiUrl = this.url.apiUrl;
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
  }

  getUserRoles(uid: number, gid: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userroles/' + uid + '/' + gid;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getRoles(page: number, size: number) {
    //tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/UserRoleDefinition/v1/' + page + '/' + size + '';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getRoleUsersAndGroups(uid: number, gid: number, roleType: string, module: string, objType: string, objId: string) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userroles/' + uid + '/' + gid + '/' + roleType + '/' + module + '/' + objType + '/' + objId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getRoleUsersAndGroupsOrg(uid: number, gid: number, roleType: string, module: string, objType: string, objId: string) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/userroles/' + this.orgId + '/' + uid + '/' + gid + '/' + roleType + '/' + module + '/' + objType + '/' + objId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getUsersAssigned(roleModule: string,roleName: string,parentType: string, parentId: string){
    const variable = new Variables();
    const savedItem = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(savedItem, variable.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl+'/api/UserRoleUsersAndGroup/v1/'+this.orgId+'/'+1+'/'+1000000+'/0/'+roleModule+'/'+parentType+'/'+roleName+'/'+parentId+'/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );

  }

  getRoleParentIds(page: number, size: number, roleName: string){
    const variable = new Variables();
    const savedItem = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(savedItem, variable.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    const url = this.apiUrl+'/api/UserRoleUsersAndGroup/v1/' + this.orgId + '/' + page + '/' + size + '/' + roleName + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );

  }

  createRole(role: Roles) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/roles';
    return this.httpclient.post<any>(url, role)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  createUserRole(role: any, usersRole: Rol[], parentId: string) {
    const requestBody=[];

    for(const userRole of usersRole){
      let userId=0;
      let groupId=0;
      if (userRole.isgroup){
        userId=userRole.Id;
      }else{
        groupId=userRole.Id;
      }
      const requestBodyItem={
        orgId: this.orgId,
        roleModule: role.roleModule,
        roleParentType: role.roleParentType,
        roleName: role.roleName,
        parentId: parentId === ' ' ? null : parentId,
        groupId: userId,
        userId: groupId,
        userGroupType: userRole.Type,
        expiry: userRole.expiry
      };
      requestBody.push(requestBodyItem);
    }

    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/UserRoleUsersAndGroup/v1';
    return this.httpclient.post<any>(url, requestBody)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  updateDeleteUserRole(role: any, usersRole: UserRoles[], parentId: string){
    const requestBody=[];
    for(const userRole of usersRole){
      let groupType='';
      if(userRole.groupId===0){
        groupType='U';
      }else{
        groupType='G';
      }

      const requestBodyItem={
        orgId: this.orgId,
        roleModule: role.roleModule,
        roleParentType: role.roleParentType,
        roleName: role.roleName,
        parentId: parentId === ' ' ? null : parentId,
        groupId: userRole.groupId,
        userId: userRole.userId,
        userGroupType: groupType,
        expiry: userRole.expiry
      };

      requestBody.push(requestBodyItem);
    }
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/UserRoleUsersAndGroup/v1/delete/1';
    return this.httpclient.put<any>(url, requestBody)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  deleteRole(id: number) {
    const url = this.apiUrl + '/api/roles';
    return this.httpclient.delete<any>(url + '/' + id)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  deleteUserRole(id: number) {
    const url = this.apiUrl + '/api/userroles';
    return this.httpclient.delete<any>(url + '/' + id)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    throw error;
    return error;
  }

}
