import { UserUpdater, KeyValue } from './../../Models/KeyValue';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/User';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/Services/users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

   user: any;

   Password: string;
   ConfirmPassword: string;

   isSave: boolean;
   loading = false;
   issave = true;

   constructor(
     private router: Router,
     private userService: UsersService,
     public translate: TranslateService,
     private notificationSvc: NotificationService,
     private confirmationService: ConfirmationService,
     private cookieService: CookieService,
     private groupService: GroupsService
   ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      this.user = JSON.parse(localStorage.getItem('user'));
    }

    async ngOnInit() {
      await this.getGroups();
     }

     async getGroups() {
      this.loading = true;
      const uid = this.user.userId;
      let mygroups = '';
      let username = '';
      let groupids = '';

      const v = new Variables();
      const va = localStorage.getItem('mg');
      const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
      const groups = mg;

      const usergroups = await this.userService.getUserGroupsByGroupIdUserId(0, uid).toPromise()
                                                  .catch(err => this.handleError(err));

      if (usergroups != null && usergroups.length > 0) {
        for (const val of usergroups) {
          groupids += val.groupId + ':';
          const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                                .catch(err => this.handleError(err));
          if (group != null && group.length > 0) {
            mygroups += group[0].groupName + ':';
          }
        }
      }

      const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
      .catch(err => this.handleError(err));
      if (user != null && user.firstName != null && user.lastName != null) {
        username = user.firstName + ' ' + user.lastName;
      }

      this.issave = true;
      if (mygroups.includes('Administrators-Full_Group')) {
        // const groups = localStorage.getItem('my-groups');
        if (groups != null && groups.includes('Administrators-Full_Group')) {
          this.issave = true;
        } else {
          this.issave = false;
        }
      }

      if (mygroups.includes('Administrators-Basic_Group')) {
        // const groups = localStorage.getItem('my-groups');
        if (groups != null && (groups.includes('Administrators-Basic_Group') || groups.includes('Administrators-Full_Group'))) {
          this.issave = true;
        } else {
          this.issave = false;
        }
      }

      this.loading = false;
    }

   onCancel() {
     this.router.navigateByUrl('/users');
   }

   onSave() {
    if (this.Password === undefined || this.Password === null || this.Password.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPASSWORD').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.ConfirmPassword === undefined || this.ConfirmPassword === null || this.ConfirmPassword.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERPASSWORDCONFIRMATION').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.Password !== this.ConfirmPassword) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PASSWORDSDONOTMATCH').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    this.isSave = true;
    this.loading = true;

    const upt = new UserUpdater();
    upt.Id = this.user.userId;
    upt.Values = [];

    const pass = new KeyValue();
    pass.key = 'pass';
    pass.Value = this.Password;
    upt.Values.push(pass);

      // tslint:disable-next-line: deprecation
    this.userService.putUser(this.user.userId, upt).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.PASSWORDCHANGED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 3000);
      });
      this.router.navigateByUrl('/users');
      this.isSave = false;
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
   }

   confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

   handleError(err) {
    console.log(err.error.MessageText);

    this.isSave = false;
    this.loading = false;
  }

}
