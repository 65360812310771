import { KeycloakService } from 'keycloak-angular';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Variables } from '../Helpers/variables';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
        private keycloakService: KeycloakService,
        private cookieService: CookieService
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // if (request.url.includes('assets') || request.url.includes('validateuser')) {
        //     //
        // } else {
        //     if (environment.useHybridMode === 'true') {
        //         const v = new Variables();
        //         const va = this.cookieService.get('tk');
        //         const token = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        //         request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        //     } else {
        //         const token: string = this.keycloakService.getKeycloakInstance().idToken;

        //         if (this.keycloakService.getKeycloakInstance().isTokenExpired() === true) {
        //             window.location.reload();
        //         }

        //         if (token) {
        //             request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        //         }
        //     }
        // }

        if (request.url.includes('assets')) {
            //
        } else {
            const token: string = this.keycloakService.getKeycloakInstance().idToken;

            if (this.keycloakService.getKeycloakInstance().isTokenExpired() === true) {
                window.location.reload();
            }

            if (token) {
                request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
            }

            if (request.headers.has('Remove')) {
                request = request.clone({ headers: request.headers.delete('Authorization') });
                request = request.clone({ headers: request.headers.delete('Remove') });
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // if (error.status === 401) {
                //     console.log(true)
                //     this.cookieService.set('tk', '', 0, '/', environment.domain, false, 'Lax');
                //     window.location.reload();
                // }
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                return throwError(error);
            }));
    }
}
