import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { DepartmentsService } from 'src/app/Services/departments/departments.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {

  depts = [];

  page = 1;
  size = 100;

  isLoadMore: boolean;
  load = false;
  ismanage = false;

  isadm = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private deptService: DepartmentsService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Departments.Manage')
          || perm.includes('USM.Administration.Full')) {
          this.ismanage = true;
      }

      if (perm.includes('USM.Administration.Full')) {
          this.isadm = true;
      }
    }
    this.getDepartments();
   }

  ngOnInit() {
    localStorage.removeItem('dept');
  }

  getDepartments() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.deptService.getDepartmentsPageSize(this.page, this.size).subscribe((dpts) => {
      if (dpts != null) {
        if (dpts.length >= this.size) {
          this.isLoadMore = false;
        } else {
          this.isLoadMore = true;
        }
        const v = new Variables();
        const gis = localStorage.getItem('gi');
        const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
        const gids = JSON.parse(gs);

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < dpts.length; i++) {
          let isedit = false;
          if (dpts[i].typeRoleGroups !== null && dpts[i].typeRoleGroups.length > 0) {
            for (const val of dpts[i].typeRoleGroups) {
              if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
                isedit = true;
              }
            }
          }
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EDIT').subscribe((res1) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe(async (res2) => {
              if (isedit === true || this.isadm === true) {
                options.push({label: res1, value: 'Edit'});
              }

              if (this.isadm === true) {
                options.push({label: res2, value: 'Delete'});
              }

              const detail = await this.translate.get('GENERAL.DETAILS').toPromise();
              options.push({label: detail, value: 'Details'});

              dpts[i].options = options;
              // if (this.ismanage === true) {
              //   dpts[i].options = options;
              // }
              this.depts.push(dpts[i]);
            });
          });
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    });
  }

  loadMore() {
    this.page += 1;
    this.getDepartments();
  }

  gotoCreateDepartment() {
    this.router.navigateByUrl('/create-department');
  }

  saveDept(event, dept) {
    localStorage.setItem('dept', JSON.stringify(dept));
    if (event.value === 'Delete') {
      this.load = true;
      // tslint:disable-next-line: deprecation
      this.deptService.deleteDepartment(dept.departmentId).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEPARTMENTDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          this.depts = [];
          this.getDepartments();
        });
      }, err => this.handleError(err));
    } else if (event.value === 'Edit') {
      this.router.navigateByUrl('/departments/' + dept.departmentId + '/edit');
    } else if (event.value === 'Details') {
      this.router.navigateByUrl('/departments/' + dept.departmentId);
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.MessageText === 'update or delete on table "t_usm_dpt" violates foreign key constraint "t_usm_user_dpt_id_fkey" on table "t_usm_user"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEPARTMENTCANNOTBEDELETED').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
