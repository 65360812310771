import { Roles } from './../../Models/Roles';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { RolesService } from 'src/app/Services/roles/roles.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.css']
})
export class CreateRoleComponent implements OnInit {

  roletypes = [];
  roletype: any;

  Module: string;
  ObjectType: string;
  ObjectId: string;
  RoleDesc: string;

  loading = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private rolesService: RolesService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    translate.get('GENERAL.ADMIN').subscribe((res1) => {
      translate.get('GENERAL.EXPERT').subscribe((res2) => {
        translate.get('GENERAL.USER').subscribe((res3) => {
          this.roletypes.push({label: res1, value: 'ADMIN'});
          this.roletypes.push({label: res2, value: 'EXPERT'});
          this.roletypes.push({label: res3, value: 'USER'});
        });
      });
    });
   }

  ngOnInit() {
  }

  onCreate() {
    this.loading = true;
    if (this.roletype === undefined || this.roletype === null) {
      this.translate.get('GENERAL.SELECTROLETYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    }

    if (this.Module === undefined || this.Module === null || this.Module.trim() === '') {
      this.translate.get('GENERAL.ENTERMODULE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    } else if (this.Module.length > 10) {
      this.translate.get('GENERAL.MODULENAMESIZEEXCEEDED').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    }

    if (this.ObjectType === undefined || this.ObjectType === null || this.ObjectType.trim() === '') {
      this.translate.get('GENERAL.ENTEROBJECTTYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    } else if (this.ObjectType.length > 20) {
      this.translate.get('GENERAL.OBJECTTYPESIZEEXCEEDED').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    }

    if (this.ObjectId === undefined || this.ObjectId === null || this.ObjectId.trim() === '') {
      this.translate.get('GENERAL.ENTEROBJECTID').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    } else if (this.ObjectId.length > 20) {
      this.translate.get('GENERAL.OBJECTIDSIZEEXCEEDED').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    }

    if (this.RoleDesc === undefined || this.RoleDesc === null || this.RoleDesc.trim() === '') {
      this.translate.get('GENERAL.ENTERROLEDESCRIPTION').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    } else if (this.RoleDesc.length > 100) {
      this.translate.get('GENERAL.ROLEDESCRIPTIONSIZEEXCEEDED').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
      return;
    }

    const role = new Roles();
    role.RoleName = this.roletype;
    role.Module = this.Module;
    role.RoleDescription = this.RoleDesc;

    this.rolesService.createRole(role).subscribe((res) => {
      this.translate.get('GENERAL.ROLECREATED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
        this.loading = false;
        this.location.back();
      });
    }, err => this.handleError(err));
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_role_role_type_module_obj_type_obj_id_key"') {
      this.translate.get('GENERAL.ROLEALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    }

    this.loading = false;
  }

}
