import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { UsersService } from 'src/app/Services/users/users.service';
import { GroupUsersService } from 'src/app/Services/group-users/group-users.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.css']
})
export class UserGroupsComponent implements OnInit {
  isDisabled: boolean;

  loading = false;
  isSave = false;

  grpId: number;

  groups = [];
  group: any;

  users1 = [];
  users2 = [];

  grp: any;

  uids = [];

  issave = true;
  ismanage = false;

  constructor(
    private groupService: GroupsService,
    public translate: TranslateService,
    private usersService: UsersService,
    private guService: GroupUsersService,
    private router: Router,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.isDisabled = true;
    /*this.translate.get('GENERAL.SELECTGROUP').subscribe((resp: string) => {
      this.groups.push({label: resp + ' *', value: null});
      this.group = this.groups[0];
    });*/

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Groups.Manage')
          || perm.includes('USM.Administration.Full')) {
          this.ismanage = true;
      } else {
        this.issave = false;
      }
    }

    this.getGroups();
    // this.getUsersByGroupId(this.grp);
    // this.getUsers();
   }

  ngOnInit() {
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.groupService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.groups.push({label: res[i].groupName, value: res[i]});
        }

        // tslint:disable-next-line: prefer-for-of
        /*for (let i = 0; i < res.length; i++) {
          if (res[i].groupId === this.grp.groupId) {
            this.group = res[i];
          }
        }*/
      }
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  getUsers() {
    this.users1 = [];
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usersService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (this.uids.includes(res[i].userId)) {
            continue;
          } else {
            res[i].fullName = res[i].firstName + res[i].lastName;
            this.users1.push(res[i]);
          }
        }
      }
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  getUsersByGroupId(grp) {
    this.issave = true;
    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const mygroups = mg;
    if (grp.groupName === 'Administrators-Full_Group') {
      // const mygroups = localStorage.getItem('my-groups');
      if (mygroups != null && mygroups.includes('Administrators-Full_Group')) {
        this.issave = true;
      } else {
        this.issave = false;
      }
    }

    if (grp.groupName === 'Administrators-Basic_Group') {
      // const mygroups = localStorage.getItem('my-groups');
      if (mygroups != null && (mygroups.includes('Administrators-Basic_Group') || mygroups.includes('Administrators-Full_Group'))
       && this.ismanage === true) {
        this.issave = true;
      } else {
        this.issave = false;
      }
    }
    if (this.issave === false) {
      return;
    }
    this.uids = [];
    if (grp !== null) {
      this.grp = grp;
      this.isDisabled = false;
      this.users2 = [];
      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.guService.getUserGroupsById(grp.groupId).subscribe((res) => {
        if (res != null) {
          let count = 0;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            this.users2.push(res[i]);
            this.uids.push(res[i].userId);
            count += 1;
            if (count === res.length) {
              this.getUsers();
              this.users2.sort((a, b) => a.firstName.localeCompare(b.firstName));
            }
          }
        } else {
          this.getUsers();
        }
        this.loading = false;
      }, err => {
        this.handleError(err);
      });
    } else {
      this.isDisabled = true;
      this.users2 = [];
    }
  }

  onCancel() {
    localStorage.setItem('route', 'groups');
    // this.router.navigateByUrl('/groups');
    this.location.back();
  }

  onSave() {
    if (this.isDisabled === false) {
      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.guService.putUserGroup(this.grp.groupId, this.users2).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SAVED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          // this.router.navigateByUrl('/groups');
          this.location.back();
          this.loading = false;
        });
      }, err => {
        this.handleError(err);
      });
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTGROUP').subscribe((resp: string) => {
        this.confirm(resp);
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
  }
}
