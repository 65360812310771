export class Roles {
    public RoleId: number;
    public RoleName: string;
    public Module: string;
    public RoleDescription: string;
}

export class Rol {
    public Id: number;
    public Name: string;
    public Type: string;
    public isuser: boolean;
    public isgroup: boolean;
    public expired = false;
    public expiry: Date;
    public expiryValue: string;
    public location = 'S';
}

export class ParentId {
    public id: string;
    public color: string;
}
