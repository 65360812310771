import { DepartmentUpdater, KeyValue } from 'src/app/Models/KeyValue';
import { Component, OnInit } from '@angular/core';
import { Department } from './../../Models/Department';
import { DepartmentsService } from 'src/app/Services/departments/departments.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { OrganisationsService } from 'src/app/Services/organisations/organisations.service';
import { DataCache } from 'src/app/Helpers/data-cache';
import { TypeRoleGroup, TypeRoleGroupData } from 'src/app/Models/role';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.css']
})
export class EditDepartmentComponent implements OnInit {

  OrgId: number;
  OrgName: string;
  DeptId: number;
  DeptName: string;
  DeptDesc: string;
  CostCenter: string;
  DeptType: string;

  loading = false;

  dpt: any;

  gs = [];

  inigroups1 = [];
  inigroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private deptService: DepartmentsService,
    private orgService: OrganisationsService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const dept = JSON.parse(localStorage.getItem('dept'));
    this.OrgId = dept.organisationId;
    this.OrgName = dept.organisationName;
    this.DeptId = dept.departmentId;
    this.DeptName = dept.departmentName;
    this.DeptDesc = dept.departmentDescription;
    this.DeptType = dept.departmentType;
    this.CostCenter = dept.costCenter;

    this.dpt = dept;
   }

  async ngOnInit() {
    await this.getGroups();
  }

  async getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    const res = await DataCache.groupdata$.toPromise();
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        res[i].GroupName = res[i].groupName;
        this.gs.push(res[i]);
      }
      this.arrangeRoles();
    }
    this.loading = false;
  }

  arrangeRoles() {
    if (this.gs !== null && this.gs.length > 0) {
      if (this.dpt.typeRoleGroups !== null && this.dpt.typeRoleGroups.length > 0) {
        for (const v of this.gs) {
          let ini = true;
          let ad = true;
          for (const val of this.dpt.typeRoleGroups) {
            if (val.typeRoleGroup.roleName === 'Initiator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.inigroups2.push(v);
                ini = false;
              }
            }
            if (val.typeRoleGroup.roleName === 'Administrator') {
              if (v.groupId === val.typeRoleGroup.groupId) {
                this.adgroups2.push(v);
                ad = false;
              }
            }
          }

          if (ini === true) {
            this.inigroups1.push(v);
          }
          if (ad === true) {
            this.adgroups1.push(v);
          }
        }
      } else {
        for (const val of this.gs) {
          this.adgroups1.push(val);
          this.inigroups1.push(val);
        }
      }
    }
  }

  getOrg() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.orgService.getOrganisationById().subscribe((res) => {
      this.OrgName = res.organisationName;
      this.loading = false;
    });
  }

  onSave() {
    this.loading = true;
    if (this.DeptName === undefined || this.DeptName === null || this.DeptName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTNAME').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptType === undefined || this.DeptType === null || this.DeptType.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTTYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptType.length > 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEPARTMENTTYPESHOULDBEONECHARACTER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.CostCenter === undefined || this.CostCenter === null || this.CostCenter.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERCOSTCENTER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptDesc === undefined || this.DeptDesc === null || this.DeptDesc.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTDESCRIPTION').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else {
      const dpt = new DepartmentUpdater();
      dpt.Id = this.DeptId;
      dpt.Values = [];

      const name = new KeyValue();
      name.key = 'name';
      name.Value = this.DeptName;
      dpt.Values.push(name);

      const desc = new KeyValue();
      desc.key = 'desc';
      desc.Value = this.DeptDesc;
      dpt.Values.push(desc);

      const type = new KeyValue();
      type.key = 'type';
      type.Value = this.DeptType;
      dpt.Values.push(type);

      const costcenter = new KeyValue();
      costcenter.key = 'cost_center';
      costcenter.Value = this.CostCenter;
      dpt.Values.push(costcenter);

      const rgroups = [];
      if (this.inigroups2 !== null && this.inigroups2.length > 0) {
        for (const val of this.inigroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.dpt.departmentId;
          rgroup.RoleName = 'Initiator';
          rgroup.RoleParentType = 'department';

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.ParentId = this.dpt.departmentId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'department';

          rgroups.push(rgroup);
        }
      }

      const data = new TypeRoleGroupData();
      data.ParentId = this.dpt.departmentId;
      data.RoleParentType = 'department';
      data.TypeRoleGroup = rgroups;

      const rg = new KeyValue();
      rg.key = 'role_groups';
      rg.Value = JSON.stringify(data);
      dpt.Values.push(rg);

    // tslint:disable-next-line: deprecation
      this.deptService.putDepartment(dpt.Id, dpt).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEPARTMENTUPDATED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.loading = false;
          this.location.back();
        });
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_dpt_org_id_dpt_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEPARETMENTALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_dpt_org_id_cost_center_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.COSTCENTERVALUEALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
