import { ExternalUsers } from './../../Models/ExternalUser';
import { UsersService } from './../../Services/users/users.service';
import { ExternalUseridTypesService } from './../../Services/external-userid-types/external-userid-types.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalUsersService } from 'src/app/Services/external-users/external-users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-create-external-user',
  templateUrl: './create-external-user.component.html',
  styleUrls: ['./create-external-user.component.css']
})
export class CreateExternalUserComponent implements OnInit {

  users = [];
  user: any;

  idtypes = [];
  idtype: any;

  ExternalId: string;

  loading = false;

  constructor(
    private extIds: ExternalUseridTypesService,
    private router: Router,
    private extUserService: ExternalUsersService,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private userService: UsersService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.getExternalIds();
    this.getUsers();
   }

  ngOnInit() {
  }

  getUsers() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.userService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.users.push({label: res[i].firstName + ' ' + res[i].lastName, value: res[i]});
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  getExternalIds() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.extIds.getExternalUserIdTypes().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.idtypes.push({label: res[i].externalIdType, value: res[i]});
        }
      }
      this.loading = false;
    }, err => this.handleError(err));
  }

  onCreate() {
    if (this.user === undefined || this.user === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTUSER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.idtype === undefined || this.idtype === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTIDTYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.ExternalId === undefined || this.ExternalId === null || this.ExternalId.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREXTERNALID').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else {
      const ext = new ExternalUsers();
      ext.UserId = this.user.userId;
      ext.ExternalUserIdType = this.idtype.externalIdType;
      ext.ExternalUserId = this.ExternalId;

      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.extUserService.postExternalUser(ext).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EXTERNALUSERCREATED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.loading = false;
          this.location.back();
        });
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_ext_uid_ext_uid_type_ext_uid_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTEREXTERNALUSERALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    }
  }

}
