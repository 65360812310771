import { Department } from './../../Models/Department';
import { DepartmentsService } from 'src/app/Services/departments/departments.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { OrganisationsService } from 'src/app/Services/organisations/organisations.service';
import { Variables } from 'src/app/Helpers/variables';
import { DataCache } from 'src/app/Helpers/data-cache';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-create-department',
  templateUrl: './create-department.component.html',
  styleUrls: ['./create-department.component.css']
})
export class CreateDepartmentComponent implements OnInit {

  OrgId: number;
  OrgName: string;
  DeptName: string;
  DeptDesc: string;
  CostCenter: string;
  DeptType: string;

  loading = false;

  inigroups1 = [];
  inigroups2 = [];

  adgroups1 = [];
  adgroups2 = [];

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private deptService: DepartmentsService,
    private orgService: OrganisationsService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

    this.OrgId = +oi;
    // this.OrgId = +localStorage.getItem('organisation-id');
    this.getGroups();
    this.getOrg();
   }

  ngOnInit() {
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.adgroups1.push(res[i]);
          this.inigroups1.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  getOrg() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.orgService.getOrganisationById().subscribe((res) => {
      this.OrgName = res.organisationName;
      this.loading = false;
    });
  }

  onCreate() {
    this.loading = true;
    if (this.DeptName === undefined || this.DeptName === null || this.DeptName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTNAME').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptType === undefined || this.DeptType === null || this.DeptType.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTTYPE').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptType.length > 1) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEPARTMENTTYPESHOULDBEONECHARACTER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.CostCenter === undefined || this.CostCenter === null || this.CostCenter.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERCOSTCENTER').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else if (this.DeptDesc === undefined || this.DeptDesc === null || this.DeptDesc.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERDEPARTMENTDESCRIPTION').subscribe((res) => {
        this.confirm(res);
        this.loading = false;
      });
    } else {
      const dept = new Department();
      dept.DepartmentName = this.DeptName;
      dept.DepartmentType = this.DeptType;
      dept.CostCenter = this.CostCenter;
      dept.DepartmentDescription = this.DeptDesc;
      dept.OrganisationId = this.OrgId;

      const rgroups = [];
      if (this.inigroups2 !== null && this.inigroups2.length > 0) {
        for (const val of this.inigroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Initiator';
          rgroup.RoleParentType = 'department';

          rgroups.push(rgroup);
        }
      }
      if (this.adgroups2 !== null && this.adgroups2.length > 0) {
        for (const val of this.adgroups2) {
          const rgroup = new TypeRoleGroup();
          rgroup.GroupId = val.groupId;
          rgroup.RoleName = 'Administrator';
          rgroup.RoleParentType = 'department';

          rgroups.push(rgroup);
        }
      }

      dept.RoleGroups = rgroups;

      // tslint:disable-next-line: deprecation
      this.deptService.postDepartment(dept).subscribe(() => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.DEPARTMENTCREATED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.loading = false;
          this.location.back();
        });
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_dpt_org_id_dpt_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.DEPARETMENTALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_dpt_org_id_cost_center_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.COSTCENTERVALUEALREADYEXISTS').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
