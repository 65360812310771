import { UsersService } from 'src/app/Services/users/users.service';
import { UserPermissionService } from './../../Services/user-permission/user-permission.service';
import { Variables } from 'src/app/Helpers/variables';
import { GroupsService } from './../../Services/groups/groups.service';
import { RolesService } from './../../Services/roles/roles.service';
import { GroupUsersService } from './../../Services/group-users/group-users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { getgroups } from 'process';
import { DataCache } from 'src/app/Helpers/data-cache';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  user: any;
  roles = [];
  roless = [];
  groups = [];

  loading = false;

  perms = [];

  gps = [];

  Icon: any;
  icon: any;
  IconName: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private cookieService: CookieService,
    private usergroupService: GroupUsersService,
    private userroleService: RolesService,
    private groupService: GroupsService,
    private permService: UserPermissionService,
    private usersService: UsersService,
    private domSanitizer: DomSanitizer
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);
      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
      this.user = JSON.parse(localStorage.getItem('user'));

      if (this.user.userImage !== undefined && this.user.userImage != null) {
        this.Icon = this.user.userImage;
        this.icon = this.domSanitizer.bypassSecurityTrustUrl(this.user.userImage);
      }
     }

  async ngOnInit() {

    await this.getGroups();

    if (this.user.typeRoleGroups !== null && this.user.typeRoleGroups.length > 0) {
      for (const val of this.user.typeRoleGroups) {
        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
            }
          }
        }
      }
    }

    this.loadRoles();
  }

  async loadRoles() {
    const grpIds = [];
    this.loading = true;
    // tslint:disable-next-line: deprecation
    const res = await this.usergroupService.getUserGroupsByGroupIdUserId(0, this.user.userId).
    toPromise();
    const gs = [];
    if (res != null) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < res.length; i++) {
        let gname = '-';
        grpIds.push(res[i].groupId);
        // tslint:disable-next-line: deprecation
        const g = await this.groupService.getGroupById(res[i].groupId).
        toPromise();
        if (g != null) {
          // tslint:disable-next-line: prefer-for-of
          for (let e = 0; e < g.length; e++) {
            // this.groups.push({label: g[e].groupName, value: g[e].groupName});
            gs.push({label: g[e].groupName, value: g[e].groupName});
            gname = g[e].groupName;
          }
        }
      }
    }

    this.groups = gs;
    await this.getPermissions(grpIds);
    this.loading = false;
  }

  loadRoles2() {
    const grpIds = [];
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.usergroupService.getUserGroupsByGroupIdUserId(0, this.user.userId).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          let gname = '-';
          grpIds.push(res[i].groupId);
          // tslint:disable-next-line: deprecation
          this.groupService.getGroupById(res[i].groupId).subscribe((g) => {
            if (g != null) {
              // tslint:disable-next-line: prefer-for-of
              for (let e = 0; e < g.length; e++) {
                this.groups.push({label: g[e].groupName, value: g[e].groupName});
                gname = g[e].groupName;
              }

    // tslint:disable-next-line: deprecation
              this.userroleService.getUserRoles(0, res[i].groupId).subscribe((resp) => {
                if (resp != null) {
                  // tslint:disable-next-line: prefer-for-of
                  for (let j = 0; j < resp.length; j++) {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.EXPERT').subscribe((trans1) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.USER').subscribe((trans2) => {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.ADMIN').subscribe((trans3) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.PANELS').subscribe((trans4) => {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.TASKS').subscribe((trans5) => {
                              // tslint:disable-next-line: deprecation
                              this.translate.get('GENERAL.INFORMATION').subscribe((trans6) => {
                                // tslint:disable-next-line: deprecation
                                this.translate.get('GENERAL.GENERAL').subscribe((trans7) => {
                                  // tslint:disable-next-line: deprecation
                                  this.translate.get('GENERAL.ADMINISTRATION').subscribe((trans8) => {
                                    if (resp[j].roleName === 'ADMIN') {
                                      resp[j].roleName = trans3;
                                    } else if (resp[j].roleName === 'USER') {
                                      resp[j].roleName = trans2;
                                    } else if (resp[j].roleName === 'EXPERT') {
                                      resp[j].roleName = trans1;
                                    }

                                    if (resp[j].objectType === 'GENERAL') {
                                      resp[j].objectType = trans7;
                                    }

                                    if (resp[j].objectType === 'TASKS') {
                                      resp[j].objectType = trans5;
                                    } else if (resp[j].objectType === 'INFORMATION') {
                                      resp[j].objectType = trans6;
                                    } else if (resp[j].objectType === 'ADMINISTRATION') {
                                      resp[j].objectType = trans8;
                                    } else if (resp[j].objectType === 'PANELS') {
                                      resp[j].objectType = trans4;
                                    }

                                    const role = resp[j].roleName + ': ' + resp[j].module + '/' + resp[j].objectType + '/' +
                                    resp[j].objectId;
                                    if (!this.roless.includes(role)) {
                                      this.roless.push(role);
                                      resp[j].groupName = gname;
                                      this.roles.push(resp[j]);
                                    }
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  }

    // tslint:disable-next-line: deprecation
                  this.userroleService.getUserRoles(this.user.userId, 0).subscribe((resp3) => {
                    if (resp3 != null) {
                      // tslint:disable-next-line: prefer-for-of
                      for (let j = 0; j < resp3.length; j++) {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.EXPERT').subscribe((trans1) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.USER').subscribe((trans2) => {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.ADMIN').subscribe((trans3) => {
                              // tslint:disable-next-line: deprecation
                              this.translate.get('GENERAL.PANELS').subscribe((trans4) => {
                                // tslint:disable-next-line: deprecation
                                this.translate.get('GENERAL.TASKS').subscribe((trans5) => {
                                  // tslint:disable-next-line: deprecation
                                  this.translate.get('GENERAL.INFORMATION').subscribe((trans6) => {
                                    // tslint:disable-next-line: deprecation
                                    this.translate.get('GENERAL.GENERAL').subscribe((trans7) => {
                                      // tslint:disable-next-line: deprecation
                                      this.translate.get('GENERAL.ADMINISTRATION').subscribe((trans8) => {
                                        if (resp3[j].roleName === 'ADMIN') {
                                          resp3[j].roleName = trans3;
                                        } else if (resp3[j].roleName === 'USER') {
                                          resp3[j].roleName = trans2;
                                        } else if (resp3[j].roleName === 'EXPERT') {
                                          resp3[j].roleName = trans1;
                                        }

                                        if (resp3[j].objectType === 'GENERAL') {
                                          resp3[j].objectType = trans7;
                                        }

                                        if (resp3[j].objectType === 'TASKS') {
                                          resp3[j].objectType = trans5;
                                        } else if (resp3[j].objectType === 'INFORMATION') {
                                          resp3[j].objectType = trans6;
                                        } else if (resp3[j].objectType === 'ADMINISTRATION') {
                                          resp3[j].objectType = trans8;
                                        } else if (resp3[j].objectType === 'PANELS') {
                                          resp3[j].objectType = trans4;
                                        }

                                        const role = resp3[j].roleName + ': ' + resp3[j].module + '/' + resp3[j].objectType + '/' +
                                        resp3[j].objectId;
                                        if (!this.roless.includes(role)) {
                                          this.roless.push(role);
                                          resp3[j].groupName = '-';
                                          this.roles.push(resp3[j]);
                                        }
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      }

                      this.load();
                    } else {
                      this.load();
                    }
                  });
                } else {
                  // tslint:disable-next-line: deprecation
                  this.userroleService.getUserRoles(this.user.userId, 0).subscribe((resp2) => {
                    if (resp2 != null) {
                      // tslint:disable-next-line: prefer-for-of
                      for (let j = 0; j < resp2.length; j++) {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.EXPERT').subscribe((trans1) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.USER').subscribe((trans2) => {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.ADMIN').subscribe((trans3) => {
                              // tslint:disable-next-line: deprecation
                              this.translate.get('GENERAL.PANELS').subscribe((trans4) => {
                                // tslint:disable-next-line: deprecation
                                this.translate.get('GENERAL.TASKS').subscribe((trans5) => {
                                  // tslint:disable-next-line: deprecation
                                  this.translate.get('GENERAL.INFORMATION').subscribe((trans6) => {
                                    // tslint:disable-next-line: deprecation
                                    this.translate.get('GENERAL.GENERAL').subscribe((trans7) => {
                                      // tslint:disable-next-line: deprecation
                                      this.translate.get('GENERAL.ADMINISTRATION').subscribe((trans8) => {
                                        if (resp2[j].roleName === 'ADMIN') {
                                          resp2[j].roleName = trans3;
                                        } else if (resp2[j].roleName === 'USER') {
                                          resp2[j].roleName = trans2;
                                        } else if (resp2[j].roleName === 'EXPERT') {
                                          resp2[j].roleName = trans1;
                                        }

                                        if (resp2[j].objectType === 'GENERAL') {
                                          resp2[j].objectType = trans7;
                                        }

                                        if (resp2[j].objectType === 'TASKS') {
                                          resp2[j].objectType = trans5;
                                        } else if (resp2[j].objectType === 'INFORMATION') {
                                          resp2[j].objectType = trans6;
                                        } else if (resp2[j].objectType === 'ADMINISTRATION') {
                                          resp2[j].objectType = trans8;
                                        } else if (resp2[j].objectType === 'PANELS') {
                                          resp2[j].objectType = trans4;
                                        }

                                        const role = resp2[j].roleName + ': ' + resp2[j].module + '/' + resp2[j].objectType + '/' +
                                        resp2[j].objectId;
                                        if (!this.roless.includes(role)) {
                                          this.roless.push(role);
                                          resp2[j].groupName = '-';
                                          this.roles.push(resp2[j]);
                                        }
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      }

                      this.load();
                    } else {
                      this.load();
                    }
                  });
                }
              });
            }
            this.loading = false;
          });
        }
      } else {
        // tslint:disable-next-line: deprecation
        this.userroleService.getUserRoles(this.user.userId, 0).subscribe((resp) => {
          if (resp != null) {
            // tslint:disable-next-line: prefer-for-of
            for (let j = 0; j < resp.length; j++) {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.EXPERT').subscribe((trans1) => {
                // tslint:disable-next-line: deprecation
                this.translate.get('GENERAL.USER').subscribe((trans2) => {
                  // tslint:disable-next-line: deprecation
                  this.translate.get('GENERAL.ADMIN').subscribe((trans3) => {
                    // tslint:disable-next-line: deprecation
                    this.translate.get('GENERAL.PANELS').subscribe((trans4) => {
                      // tslint:disable-next-line: deprecation
                      this.translate.get('GENERAL.TASKS').subscribe((trans5) => {
                        // tslint:disable-next-line: deprecation
                        this.translate.get('GENERAL.INFORMATION').subscribe((trans6) => {
                          // tslint:disable-next-line: deprecation
                          this.translate.get('GENERAL.GENERAL').subscribe((trans7) => {
                            // tslint:disable-next-line: deprecation
                            this.translate.get('GENERAL.ADMINISTRATION').subscribe((trans8) => {
                              if (resp[j].roleName === 'ADMIN') {
                                resp[j].roleName = trans3;
                              } else if (resp[j].roleName === 'USER') {
                                resp[j].roleName = trans2;
                              } else if (resp[j].roleName === 'EXPERT') {
                                resp[j].roleName = trans1;
                              }

                              if (resp[j].objectType === 'GENERAL') {
                                resp[j].objectType = trans7;
                              }

                              if (resp[j].objectType === 'TASKS') {
                                resp[j].objectType = trans5;
                              } else if (resp[j].objectType === 'INFORMATION') {
                                resp[j].objectType = trans6;
                              } else if (resp[j].objectType === 'ADMINISTRATION') {
                                resp[j].objectType = trans8;
                              } else if (resp[j].objectType === 'PANELS') {
                                resp[j].objectType = trans4;
                              }

                              const role = resp[j].roleName + ': ' + resp[j].module + '/' + resp[j].objectType + '/' +
                              resp[j].objectId;
                              if (!this.roless.includes(role)) {
                                this.roless.push(role);
                                resp[j].groupName = '-';
                                this.roles.push(resp[j]);
                              }
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            }

            this.load();
          } else {
            this.load();
          }
        });
      }
    });
  }

  async getPermissions(gs: any) {
    const groupids = gs;
    if (groupids != null && groupids.length > 0) {
      // const gids = groupids.split(':');
      const gids = groupids;
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, id, ' ').toPromise()
            .catch(err => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                if (!this.perms.includes(perm.perm)) {
                  this.perms.push(perm.perm);
                }
              }
            }
          }
        }
      }
    }
  }

  async getGroups() {
    this.loading = true;
    const data = await DataCache.groupdata$.toPromise();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
      }
    }
    this.loading = false;
  }

  load() {
  }

  handleError(err) {
    this.loading = false;
    console.log(err);
  }

}
