import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { GroupUsersService } from './../../Services/group-users/group-users.service';
import { UsersService } from './../../Services/users/users.service';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { Component, OnInit } from '@angular/core';
import { group } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-group-users',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.css']
})
export class GroupUsersComponent implements OnInit {
  isDisabled: boolean;

  loading = false;
  isSave = false;

  grpId: number;

  groups = [];
  group: any;

  users1 = [];
  users2 = [];

  grp: any;

  uids = [];

  issave = true;

  constructor(
    private groupService: GroupsService,
    public translate: TranslateService,
    private usersService: UsersService,
    private guService: GroupUsersService,
    private router: Router,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.isDisabled = true;
    this.grp = JSON.parse(localStorage.getItem('group'));
    this.grpId = this.grp.groupId;

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTGROUP').subscribe((resp: string) => {
      this.groups.push({label: resp + ' *', value: null});
    });

    this.getGroups();
    this.getUsersByGroupId(this.grp);
   }

  ngOnInit() {
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.groupService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          this.groups.push({label: res[i].groupName, value: res[i]});
        }

        const v = new Variables();
        const va = localStorage.getItem('mg');
        const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
        const mygroups = mg;

        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          if (res[i].groupId === this.grp.groupId) {
            this.group = res[i];
            if (res[i].groupName === 'Administrators-Full_Group') {
              // const mygroups = localStorage.getItem('my-groups');
              if (mygroups != null && mygroups.includes('Administrators-Full_Group')) {
                this.issave = true;
              } else {
                this.issave = false;
              }
            }

            if (res[i].groupName === 'Administrators-Basic_Group') {
              // const mygroups = localStorage.getItem('my-groups');
              if (mygroups != null && (mygroups.includes('Administrators-Basic_Group') || mygroups.includes('Administrators-Full_Group'))) {
                this.issave = true;
              } else {
                this.issave = false;
              }
            }
          }
        }
      }
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  getUsers() {
    this.loading = true;
    this.users1 = [];
    // tslint:disable-next-line: deprecation
    this.usersService.getUsers().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].fullName = res[i].firstName + ' ' + res[i].lastName;
          if (this.uids.includes(res[i].userId)) {
            continue;
          } else {
            this.users1.push(res[i]);
          }
        }
      }
      this.loading = false;
    }, err => {
      this.handleError(err);
    });
  }

  getUsersByGroupId(grp) {
    this.uids = [];
    if (grp !== null) {
      this.isDisabled = false;
      this.users2 = [];
      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.guService.getUserGroupsById(grp.groupId).subscribe((res) => {
        if (res != null) {
          let count = 0;
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < res.length; i++) {
            this.users2.push(res[i]);
            this.uids.push(res[i].userId);
            count += 1;
            if (count === res.length) {
              this.getUsers();
              this.users2.sort((a, b) => a.firstName.localeCompare(b.firstName));
            }
          }
        } else {
          this.getUsers();
        }
        this.loading = false;
      }, err => {
        this.handleError(err);
      });
    } else {
      this.isDisabled = true;
      this.users2 = [];
    }
  }

  onCancel() {
    // this.router.navigateByUrl('/groups');
    this.location.back();
  }

  onSave() {
    if (this.isDisabled === false) {
      this.loading = true;
      // tslint:disable-next-line: deprecation
      this.guService.putUserGroup(this.grp.groupId, this.users2).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.SAVED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          // this.router.navigateByUrl('groups');
          this.location.back();
          this.loading = false;
        });
      }, err => {
        this.handleError(err);
      });
    } else {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTGROUP').subscribe((resp: string) => {
        this.confirm(resp);
      });
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.loading = false;
  }

}
