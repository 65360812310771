import { OrganisationsService } from './../../Services/organisations/organisations.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { ConfirmationService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-organisations',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.css']
})
export class OrganisationsComponent implements OnInit {

  orgs = [];

  page = 1;
  size = 100;

  isLoadMore: boolean;
  load = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private orgService: OrganisationsService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.getOrganisations();
   }

  ngOnInit() {
    localStorage.removeItem('org');
  }

  getOrganisations() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.orgService.getOrganisationsPageSize(this.page, this.size).subscribe((ogs) => {
      if (ogs != null) {
        if (ogs.length >= this.size) {
          this.isLoadMore = false;
        } else {
          this.isLoadMore = true;
        }
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < ogs.length; i++) {
          const options = [];
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.EDIT').subscribe((res1) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DELETE').subscribe((res2) => {
              // options.push({label: res1, value: 'Edit'});
              if (ogs[i].organisationName !== 'ORG_SUPER_ADMIN') {
                options.push({label: res2, value: 'Delete'});
                ogs[i].dis = null;
              } else {
                ogs[i].dis = 'true';
              }

              ogs[i].options = options;
              this.orgs.push(ogs[i]);
            });
          });
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    });
  }

  loadMore() {
    this.page += 1;
    this.getOrganisations();
  }

  gotoCreateOrganisation() {
    this.router.navigateByUrl('/create-organisation');
  }

  saveOrg(event, org) {
    localStorage.setItem('org', JSON.stringify(org));
    if (event.value === 'Delete') {
      this.load = true;
      // tslint:disable-next-line: deprecation
      this.orgService.deleteOrganisation(org.organisationId).subscribe((res) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.ORGANISATIONDELETED').subscribe((resp: string) => {
          this.notificationSvc.info('', resp, 2000);
          this.orgs = [];
          this.getOrganisations();
        });
      }, err => this.handleError(err));
    } else if (event.value === 'Edit') {
      this.router.navigateByUrl('/organisations/' + org.organisationId + '/edit');
    }
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
  }

  handleError(err) {
    this.load = false;
    // tslint:disable-next-line: max-line-length
    if (err.error.MessageText === 'update or delete on table "t_usm_orga" violates foreign key constraint "t_usm_dpt_org_id_fkey" on table "t_usm_dpt"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ORGANISATIONCANNOTBEDELETED').subscribe((res) => {
        this.confirm(res);
      });
    }
  }

}
