import { DepartmentDetailComponent } from './Components/department-detail/department-detail.component';
import { BrowserComponent } from './Components/browser/browser.component';
import { RoleService } from './Services/guard/role/role.service';
import { NotFoundComponent } from './Components/not-found/not-found.component';
import { OrganisationService } from './Services/guard/organisation/organisation.service';
import { ManageDepartmentService } from './Services/guard/manage-department/manage-department.service';
import { ManageRoleService } from './Services/guard/manage-role/manage-role.service';
import { PermissionService } from './Services/guard/permission/permission.service';
import { ManageExtuserService } from './Services/guard/manage-extuser/manage-extuser.service';
import { ExternalUserService } from './Services/guard/ext-user/external-user.service';
import { ManageGroupService } from './Services/guard/manage-group/manage-group.service';
import { ManageUserService } from './Services/guard/manage-user/manage-user.service';
import { UsergroupService } from './Services/guard/usergroup/usergroup.service';
import { GroupService } from './Services/guard/group/group.service';
import { UserService } from './Services/guard/user/user.service';
import { DepartmentService } from './Services/guard/department/department.service';
import { PermissionComponent } from './Components/permission/permission.component';
import { CreateOrganisationComponent } from './Components/create-organisation/create-organisation.component';
import { OrganisationsComponent } from './Components/organisations/organisations.component';
import { EditDepartmentComponent } from './Components/edit-department/edit-department.component';
import { CreateDepartmentComponent } from './Components/create-department/create-department.component';
import { DepartmentsComponent } from './Components/departments/departments.component';
import { UnauthorizedUserComponent } from './Components/unauthorized-user/unauthorized-user.component';
import { CreateRoleComponent } from './Components/create-role/create-role.component';
import { RoleUsersGroupsComponent } from './Components/role-users-groups/role-users-groups.component';
import { RolesComponent } from './Components/roles/roles.component';
import { GroupDetailComponent } from './Components/group-detail/group-detail.component';
import { UserDetailComponent } from './Components/user-detail/user-detail.component';
import { CreateExternalUserComponent } from './Components/create-external-user/create-external-user.component';
import { EditExternalUserComponent } from './Components/edit-external-user/edit-external-user.component';
import { ExternalUsersComponent } from './Components/external-users/external-users.component';
import { CreateGroupComponent } from './Components/create-group/create-group.component';
import { GroupUsersComponent } from './Components/group-users/group-users.component';
import { EditGroupComponent } from './Components/edit-group/edit-group.component';
import { ChangePinComponent } from './Components/change-pin/change-pin.component';
import { ChangePasswordComponent } from './Components/change-password/change-password.component';
import { NotificationComponent } from './Components/notification/notification.component';
import { EditUserComponent } from './Components/edit-user/edit-user.component';
import { CreateUserComponent } from './Components/create-user/create-user.component';
import { UserGroupsComponent } from './Components/user-groups/user-groups.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, enableProdMode } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { UsersComponent } from './Components/users/users.component';
import { GroupsComponent } from './Components/groups/groups.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccordionModule } from 'primeng/accordion';     // accordion and accordion tab
import { MessageService, ConfirmationService } from 'primeng/api';                  // api
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MenubarModule } from 'primeng/menubar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PickListModule } from 'primeng/picklist';
import { ListboxModule } from 'primeng/listbox';

import { CookieService } from 'ngx-cookie-service';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { HttpConfigInterceptor } from './Interceptors/httpconfig.interceptor';
import { detect } from 'detect-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

enableProdMode();

export function HttpLoaderFactory(httpClient: HttpClient) {
   return new TranslateHttpLoader(httpClient);
 }

const keycloakService = new KeycloakService();
const browser = detect();

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    GroupsComponent,
    UserGroupsComponent,
    CreateUserComponent,
    EditUserComponent,
    NotificationComponent,
    ChangePinComponent,
    ChangePasswordComponent,
    EditGroupComponent,
    GroupUsersComponent,
    CreateGroupComponent,
    ExternalUsersComponent,
    CreateExternalUserComponent,
    EditExternalUserComponent,
    UserDetailComponent,
    GroupDetailComponent,
    RolesComponent,
    RoleUsersGroupsComponent,
    CreateRoleComponent,
    UnauthorizedUserComponent,
    DepartmentsComponent,
    CreateDepartmentComponent,
    EditDepartmentComponent,
    OrganisationsComponent,
    CreateOrganisationComponent,
    PermissionComponent,
    NotFoundComponent,
    BrowserComponent,
    DepartmentDetailComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    AccordionModule,
    PanelModule,
    ButtonModule,
    RadioButtonModule,
    ToolbarModule,
    SplitButtonModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    MenubarModule,
    InputTextModule,
    ProgressSpinnerModule,
    PickListModule,
    ListboxModule,
    ToastModule,
    CalendarModule,
    CardModule,
    DialogModule,
    TabViewModule,
    CheckboxModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot([
      // { path: '404', component: NotFoundComponent },
      // { path: '**', redirectTo: '/404' },
      { path: 'users', component: UsersComponent, canActivate: [ UserService ] },
      { path: 'groups', component: GroupsComponent, canActivate: [ GroupService ] },
      { path: 'usergroups', component: UserGroupsComponent, canActivate: [ UsergroupService ] },
      { path: 'createuser', component: CreateUserComponent, canActivate: [ ManageUserService ] },
      { path: 'users/:id/edit', component: EditUserComponent, canActivate: [ ManageUserService ] },
      { path: 'users/:id/changepassword', component: ChangePasswordComponent, canActivate: [ ManageUserService ] },
      { path: 'users/:id/changepin', component: ChangePinComponent, canActivate: [ ManageUserService ] },
      { path: 'groups/:id/edit', component: EditGroupComponent, canActivate: [ ManageGroupService ] },
      { path: 'groups/:id/groupusers', component: GroupUsersComponent, canActivate: [ UsergroupService ] },
      { path: 'creategroup', component: CreateGroupComponent, canActivate: [ ManageGroupService ] },
      { path: 'externalusers', component: ExternalUsersComponent, canActivate: [ ExternalUserService ] },
      { path: 'createexternaluser', component: CreateExternalUserComponent, canActivate: [ ManageExtuserService ] },
      { path: 'externalusers/:id', component: EditExternalUserComponent, canActivate: [ ManageExtuserService ] },
      { path: 'users/:id', component: UserDetailComponent, canActivate: [ UserService ] },
      { path: 'groups/:id', component: GroupDetailComponent, canActivate: [ GroupService ] },
      { path: 'roles', component: RolesComponent, canActivate: [ RoleService ] },
      { path: 'permissions', component: PermissionComponent, canActivate: [ PermissionService ] },
      { path: 'createrole', component: CreateRoleComponent, canActivate: [ ManageRoleService ] },
      { path: 'managerole', component: RoleUsersGroupsComponent, canActivate: [ ManageRoleService ] },
      { path: 'departments', component: DepartmentsComponent, canActivate: [ DepartmentService ] },
      { path: 'create-department', component: CreateDepartmentComponent, canActivate: [ ManageDepartmentService ] },
      { path: 'departments/:id', component: DepartmentDetailComponent, canActivate: [ DepartmentService ] },
      { path: 'departments/:id/edit', component: EditDepartmentComponent, canActivate: [ ManageDepartmentService ] },
      { path: 'organisations', component: OrganisationsComponent, canActivate: [ OrganisationService ] },
      { path: 'create-organisation', component: CreateOrganisationComponent, canActivate: [ OrganisationService ] },
      { path: 'unauthorizeduser', component: UnauthorizedUserComponent },
      { path: 'xxxxx', component: BrowserComponent }
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    MessageService,
    CookieService,
    ConfirmationService,
    {
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  {
    provide: KeycloakService,
    useValue: keycloakService
  },
  { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
],
  //  bootstrap: [
  //     AppComponent
  //  ]
  entryComponents: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    ) {
    localStorage.setItem('oi', '');
    localStorage.setItem('ui', '');
  }

  ngDoBootstrap(app) {
    localStorage.setItem('browser', 'true');
    keycloakService
        .init(
          {
            config: {
              url: environment.keycloakUrl + '/auth',
              realm: environment.realm,
              clientId: environment.clientid
            },
            initOptions: {
              onLoad: 'login-required',
              checkLoginIframe: false
            },
            enableBearerInterceptor: true,
            bearerPrefix: 'Bearer',
            bearerExcludedUrls: [
                '/assets',
                '/clients/public']
          })
        .then(async () => {
          // console.log('[ngDoBootstrap] bootstrap app')

          // console.log((await keycloakService.loadUserProfile()).email)

          localStorage.setItem('oi', '');
          localStorage.setItem('ui', '');
          app.bootstrap(AppComponent);
        })
        .catch(
          error => console.error('[ngDoBootstrap] init Keycloak failed', error)
        );
  }
}
