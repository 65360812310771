import { UserRoles } from './../../Models/UserRoles';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { RolesService } from 'src/app/Services/roles/roles.service';
import { Rol } from 'src/app/Models/Roles';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { UsersService } from 'src/app/Services/users/users.service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-role-users-groups',
  templateUrl: './role-users-groups.component.html',
  styleUrls: ['./role-users-groups.component.css']
})
export class RoleUsersGroupsComponent implements OnInit {

  usersGroupNoSelected = [];
  usersGroupSelectedTarget: Rol[] = [];
  gts=[];
  Role: string;
  selectedRole: any;
  load=false;
  usersGroupSelected:UserRoles[] = [];
  isManage = false;

  displayExpiryDateModal = false;
  expiryDate: Date;

  toBeAdded = [];
  toBeRemoved = [];

  mover: any;

  constructor(
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private cookieService: CookieService,
    private rolesService: RolesService,
    private location: Location,
    private groupService: GroupsService,
    private usersService: UsersService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();
    const pass = 'ranitessarldev2019';
    const lang = this.cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }

    this.load=true;
    this.translate.use(RegExp(/English|French/).exec(browserLang) ? browserLang : 'English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Roles.Manage') || perm.includes('USM.Administration.Full')) {
        this.isManage = true;
      }
    }

    this.selectedRole = JSON.parse(localStorage.getItem('role'));
    this.Role = this.selectedRole.roleModule + '/' + this.selectedRole.roleParentType + '/' + this.selectedRole.roleName;
    this.getUsersInRole();
  }

  ngOnInit(){
    // init
  }

   //getUsersAssigned(roleModule: string, roleParenType: string,roleName: string)
   getUsersInRole() {
     this.rolesService.getUsersAssigned(this.selectedRole.roleModule,this.selectedRole.roleName,this.selectedRole.roleParentType, ' ').subscribe((resultData) => {
      console.log(resultData)
       if (resultData != null) {
         for (const element of resultData) {
          const rol = new Rol();
          rol.expiry = new Date(element.expiry);
          rol.expired = false;
          if (new Date(element.expiry) < new Date()) {
            rol.expired = true;
          }

          if (element.userId > 0) {
            rol.Id = element.userId;
            rol.Name = element.userFirstName + ' ' + element.userLastName;
            rol.Type = 'U';
            rol.isuser = true;
            rol.isgroup = false;
            this.gts.push('U' + rol.Id);
            this.usersGroupSelectedTarget.push(rol);

            const r = new UserRoles();
            r.roleName = this.selectedRole.roleName;
            r.roleModule = this.selectedRole.roleModule;
            r.userId = rol.Id;
            r.groupId = 0;
            this.usersGroupSelected.push(r);
          } else if (element.groupId > 0) {
            rol.Id = element.groupId;
            rol.Name = element.groupName;
            rol.Type = 'G';
            rol.isuser = false;
            rol.isgroup = true;
            this.gts.push('G' + rol.Id);
            this.usersGroupSelectedTarget.push(rol);

            const r = new UserRoles();
            r.roleName = this.selectedRole.roleName;
            r.roleModule = this.selectedRole.roleModule;
            r.userId = 0;
            r.groupId = rol.Id;
            this.usersGroupSelected.push(r);
          }
         }
         this.getUsers();
       } else {
        this.getUsers();
       }
     });
   }

  getGroups(){
    this.groupService.getGroups().subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (const element of res) {
          if (this.gts.includes('G' + element.groupId)) {
            continue;
          } else {
            const rol = new Rol();
            rol.Id = element.groupId;
            rol.Name = element.groupName;
            rol.Type = 'G';
            rol.isuser = false;
            rol.isgroup = true;
            this.usersGroupNoSelected.push(rol);
          }
        }
        this.usersGroupNoSelected.sort((a, b) => a.Name.localeCompare(b.Name));
        this.load=false;
      }
    }, err => {
      this.handleError(err);
    });
  }

  getUsers() {
    this.usersService.getUsers().subscribe((res) =>{
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (const element of res) {
          element.fullName = element.firstName + ' ' + element.lastName;
          if (this.gts.includes('U' + element.userId)) {
            continue;
          } else {
            const rol = new Rol();
            rol.Id = element.userId;
            rol.Name = element.fullName;
            rol.Type = 'U';
            rol.isuser = true;
            rol.isgroup = false;
            this.usersGroupNoSelected.push(rol);
          }
        }
        this.getGroups();
      } else {
        this.getGroups();
      }
    }, err => {
      this.handleError(err);
    });
  }

  onMoveToTarget(event: any) {
    this.mover = event.items;
    this.expiryDate = undefined;
    this.displayExpiryDateModal = true;
  }

  onMoveToSource(event: any) {
    for (const val of event.items) {
      val.expired = false;
    }
  }

  confirm() {
    for (const val of this.mover) {
      const elm = this.usersGroupSelectedTarget.find(x => x.Id === val.Id && x.Type === val.Type);
      elm.expiry = this.expiryDate;
      if (new Date(elm.expiry) < new Date()) {
        elm.expired = true;
      } else {
        elm.expired = false;
      }

      this.usersGroupSelectedTarget.splice(this.usersGroupSelectedTarget.findIndex(x => x.Id === val.Id && x.Type === val.Type), 1, elm);
    }

    this.expiryDate = undefined;
    this.displayExpiryDateModal = false;
  }

  cancel() {
    for(const val of this.mover) {
      this.usersGroupSelectedTarget.splice(this.usersGroupSelectedTarget.findIndex(x => x.Id === val.Id && x.Type === val.Type), 1);
      this.usersGroupNoSelected.push(val);
      this.usersGroupNoSelected.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    this.expiryDate = undefined;
    this.displayExpiryDateModal = false;
  }

  onSave() {
    this.load=true;
    if(this.usersGroupSelectedTarget === undefined || this.usersGroupSelectedTarget === null || this.usersGroupSelectedTarget.length <= 0){
      this.rolesService.updateDeleteUserRole(this.selectedRole, this.usersGroupSelected,  '').subscribe((res) => {
        this.translate.get('GENERAL.SAVED').subscribe((resp) => {
          this.notificationSvc.info('', resp, 2000);
          this.location.back();
        });
      }, err => this.handleError(err));
    } else {
      const roles = [];
      this.rolesService.updateDeleteUserRole(this.selectedRole, this.usersGroupSelected,  '').subscribe((res) => {
          this.rolesService.createUserRole(this.selectedRole,this.usersGroupSelectedTarget,  '').subscribe((res1) => {
            this.translate.get('GENERAL.SAVED').subscribe((resp) => {
              this.notificationSvc.info('', resp, 2000);
              this.location.back();
            });
          }, err => this.handleError(err));
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.location.back();
  }

  setTestExpiredDate() {
    this.expiryDate = new Date(new Date().setDate(new Date().getDate() - 1));
  }

  setTestNotDate() {
    this.expiryDate = new Date(new Date().setDate(new Date().getDate() + 1));
  }

  handleError(err) {
    console.log(err);
  }

}
