import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ManageDepartmentService implements CanActivate {

  constructor() { }

  canActivate() {
    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p == null) {
      return false;
    }
    const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
    const perm = JSON.parse(val);
    if (perm.includes('USM.Departments.Manage')
        || perm.includes('USM.Administration.Full')) {
          return true;
    } else {
      return false;
    }
  }

}
