import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { DataCache } from 'src/app/Helpers/data-cache';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-department-detail',
  templateUrl: './department-detail.component.html',
  styleUrls: ['./department-detail.component.scss']
})
export class DepartmentDetailComponent implements OnInit {
  loading = false;
  gps = [];

  dpt: any;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private cookieService: CookieService
    ) {
      this.translate.addLangs(['English', 'French']);
      this.translate.setDefaultLang('English');

      let browserLang = this.translate.getBrowserLang();

      const pass = 'ranitessarldev2019';
      const lang = cookieService.get('goepla-portal-language');
      if (lang.trim() !== '') {
        const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
        const lg = declang;
        browserLang = lg;
        localStorage.setItem('language', lg);

      }

      if (localStorage.getItem('language') !== null) {
        browserLang = localStorage.getItem('language');
      }
      this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

      this.dpt = JSON.parse(localStorage.getItem('dept'));
  }

  async ngOnInit() {
    await this.getGroups();

    if (this.dpt.typeRoleGroups !== null && this.dpt.typeRoleGroups.length > 0) {
      for (const val of this.dpt.typeRoleGroups) {
        val.typeRoleGroup.g = null;
        if (this.gps !== undefined && this.gps !== null && this.gps.length > 0) {
          for (const g of this.gps) {
            if (val.typeRoleGroup.groupId === g.groupId) {
              val.typeRoleGroup.g = g.groupName;
            }
          }
        }
      }
    }
  }

  async getGroups() {
    this.loading = true;
    const data = await DataCache.groupdata$.toPromise();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.gps.push(val);
      }
    }
    this.loading = false;
  }

}
