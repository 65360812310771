export class KeyValue {
    public key: string;
    public Value: string;
}

export class UserUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class ExtUserUpdater {
    public ExtId: string;
    public Values: KeyValue[];
}

export class GroupUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class OrganisationUpdater {
    public Id: number;
    public Values: KeyValue[];
}

export class DepartmentUpdater {
    public Id: number;
    public Values: KeyValue[];
}
