import { Injectable } from '@angular/core';
import { Urls } from 'src/app/Helpers/Urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Organisation } from 'src/app/Models/Organisation';
import { Observable } from 'rxjs';
import { OrganisationUpdater } from 'src/app/Models/KeyValue';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {
  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
      this.apiUrl = this.url.apiUrl;
      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
      this.orgId = +oi;
  }

  getOrganisations() {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/organisations';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getOrganisationsPageSize(page: number, size: number) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/organisations/' + page + '/' + size;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  getOrganisationById() {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/organisations/' + this.orgId;
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  postOrganisation(org: Organisation): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.post(this.apiUrl + '/api/organisations', org, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  putOrganisation(id: number, org: OrganisationUpdater): Observable<any> {
    // const body = JSON.stringify(inst);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.put(this.apiUrl + '/api/organisations/' + id, org, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  deleteOrganisation(id: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpclient.delete<any>(this.apiUrl + '/api/organisations/' + id, options)
                    .pipe(
                      catchError(this.handleError)
                    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }
}
