import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class Group {
    public GroupId: number;
    public OrganisationId: number;
    public GroupName: string;
    public GroupDescription: string;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
    public GroupTypeId: number;
}
