import { formatDate } from '@angular/common';
import { RolesService } from './../../Services/roles/roles.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { ParentId, Rol } from 'src/app/Models/Roles';
import { UserRoles } from 'src/app/Models/UserRoles';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { UsersService } from 'src/app/Services/users/users.service';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  roles = [];
  load = false;

  isManage = true;

  contentdiv = '';
  picklistheight = '';

  showdetail = false;
  showparentids = false;

  loadmore = false;

  page = 1;
  size = 500;

  usersGroupNoSelected = [];
  usersGroupSelectedTarget: Rol[] = [];
  gts=[];
  Role: string;
  selectedRole: any;
  usersGroupSelected:UserRoles[] = [];

  displayExpiryDateModal = false;
  expiryDate: Date;

  toBeAdded = [];
  toBeRemoved = [];

  mover: any;

  parentId = ' ';

  tempusersGroupSelectedTarget = [];
  tempusersGroupSelected = [];
  tempusersGroupNoSelected = [];

  allusersNoSelected = [];
  allgroupNoSelected = [];

  parentIds = [];

  parentIdPage = 1;

  picklistSectionWith = '100%';
  parentIdsDivHeight = '';

  checked: boolean = false;

  noManage = true;

  tempRolesInGroup = [];

  constructor(
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private cookieService: CookieService,
    private rolesService: RolesService,
    private groupService: GroupsService,
    private usersService: UsersService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = this.cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(RegExp(/English|French/).exec(browserLang) ? browserLang : 'English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Roles.Manage')
          || perm.includes('USM.Administration.Full')) {
        this.noManage = false;
      }
    }

    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;

    if (groups.includes('Administrators-Full_Group')) {
      this.noManage = false;
    }

    this.getRoles();
  }

  ngOnInit(){
    this.onResize(null);
    localStorage.removeItem('role');
  }

  getRoles() {
    this.load = true;
    this.rolesService.getRoles(this.page, this.size).subscribe((rls) => {
      if (rls != null) {
        for (const element of rls) {
          element.color = 'transparent';
          this.roles.push(element);
        }
      }
      this.load = false;
    }, err => { console.log(err); this.load = false; });
  }

  loadMoreRoles() {
    this.page += 1;
    this.getRoles();
  }

  async roleClicked(role) {
    this.parentId = ' ';
    this.selectedRole = role;

    this.load = true;

    for (const p of this.roles) {
      if (p.roleName === role.roleName) {
        p.color = 'lightgray';
      } else {
        p.color = 'transparent';
      }
    }

    await this.getParentIds(role);

    this.load = false;
  }

  async parentIdClicked(id: ParentId) {
    this.parentId = id.id;

    this.load = true;

    for (const p of this.parentIds) {
      if (p.id === id.id) {
        p.color = 'lightgray';
      } else {
        p.color = 'transparent';
      }
    }

    await this.loadManager(this.selectedRole);

    this.load = false;
  }

  onResize(event) {
    const menuheight = document.getElementById('menu').offsetHeight;
    const contentheight = window.innerHeight;

    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
        this.contentdiv = (+contentheight - menuheight - 50) + 'px';
        this.picklistheight = (+contentheight - menuheight - 205) + 'px';
        this.parentIdsDivHeight = (+contentheight - menuheight - 75) + 'px';
    } else {
      this.contentdiv = '85vh';
      this.picklistheight = '55vh';
      this.parentIdsDivHeight = '87vh';
    }
  }

  async getParentIds(role: any, clear = true) {
    const resultData = await this.rolesService.getRoleParentIds(this.parentIdPage, this.size, role.roleName).toPromise().catch(err => this.handleError(err));

    if (clear) {
      this.parentIds = [];
    }

    if (resultData !== null && resultData.length > 0) {
      for (const val of resultData) {
        const pid = new ParentId();
        pid.id = val;
        pid.color = 'transparent';
        this.parentIds.push(pid);
      }
      this.picklistSectionWith = '70%';
      this.showparentids = true;
      this.showdetail = false;
    } else if (clear) {
      this.picklistSectionWith = '100%';
      this.showparentids = false;

      await this.loadManager(role);
    }
  }

  async loadMoreParentIds() {
    this.parentIdPage += 1;
    this.load = true;
    await this.getParentIds(this.selectedRole, false);
    this.load = false;
  }

  async loadManager(role) {
    await this.getUsersInRole(role);

    this.tempusersGroupSelectedTarget = [];
    this.tempusersGroupSelected = [];
    this.tempusersGroupNoSelected = [];
    for (const val of this.usersGroupSelectedTarget) {
      this.tempusersGroupSelectedTarget.push(val);
    }
    for (const val of this.usersGroupSelected) {
      this.tempusersGroupSelected.push(val);
    }
    for (const val of this.usersGroupNoSelected) {
      this.tempusersGroupNoSelected.push(val);
    }

    this.resetSearch();

    this.showdetail = true;
  }

  resetSearch() {
    const resetFilterSearch = document.getElementById('reset-filter-search');
    if (resetFilterSearch !== undefined && resetFilterSearch !== null) {
      resetFilterSearch.click();
    }
  }

  async getUsersInRole(role: any) {
    const resultData = await this.rolesService.getUsersAssigned(role.roleModule, role.roleName, role.roleParentType, this.parentId).toPromise().catch(err => this.handleError(err));
    this.usersGroupSelectedTarget = [];
    this.usersGroupSelected = [];
    this.tempRolesInGroup = [];

    this.gts = [];
    if (resultData != null) {
      for (const element of resultData) {
        const rol = new Rol();
        rol.location = 'T';
        rol.expiry = new Date(element.expiry);
        if (rol.expiry.getFullYear() > 2049) {
          this.translate.get('GENERAL.NEVEREXPIRE').subscribe((resp) => {
            rol.expiryValue = resp;
          });
        } else {
          rol.expiryValue = formatDate(element.expiry, 'dd MMM yyy', 'en-US');
        }
        rol.expired = false;
        if (new Date(element.expiry) < new Date()) {
          rol.expired = true;
        }

        if (element.userId > 0) {
          rol.Id = element.userId;
          rol.Name = element.userFirstName + ' ' + element.userLastName;
          rol.Type = 'U';
          rol.isuser = true;
          rol.isgroup = false;
          this.gts.push('U' + rol.Id);
          this.usersGroupSelectedTarget.push(rol);

          const r = new UserRoles();
          r.roleName = this.selectedRole.roleName;
          r.roleModule = this.selectedRole.roleModule;
          r.userId = rol.Id;
          r.groupId = 0;
          this.usersGroupSelected.push(r);
          this.tempRolesInGroup.push(r);
        } else if (element.groupId > 0) {
          rol.Id = element.groupId;
          rol.Name = element.groupName;
          rol.Type = 'G';
          rol.isuser = false;
          rol.isgroup = true;
          this.gts.push('G' + rol.Id);
          this.usersGroupSelectedTarget.push(rol);

          const r = new UserRoles();
          r.roleName = this.selectedRole.roleName;
          r.roleModule = this.selectedRole.roleModule;
          r.userId = 0;
          r.groupId = rol.Id;
          this.usersGroupSelected.push(r);
          this.tempRolesInGroup.push(r);
        }
      }
      await this.getUsers();
    } else {
      await this.getUsers();
    }
  }

  async getGroups(data: any){
    let res = [];
    if (this.allgroupNoSelected.length > 0) {
      for (const val of this.allgroupNoSelected) {
        res.push(val);
      }
    } else {
      res = await this.groupService.getGroups().toPromise().catch(err => this.handleError(err));
    }

    this.usersGroupNoSelected = data;

    this.allgroupNoSelected = [];

    if (res != null && res.length > 0) {
      for (const element of res) {
        this.allgroupNoSelected.push(element);
        if (this.gts.includes('G' + element.groupId)) {
          continue;
        } else {
          const rol = new Rol();
          rol.Id = element.groupId;
          rol.Name = element.groupName;
          rol.Type = 'G';
          rol.isuser = false;
          rol.isgroup = true;
          this.usersGroupNoSelected.push(rol);
        }
      }
      this.usersGroupNoSelected.sort((a, b) => a.Name.localeCompare(b.Name));
      this.load=false;
    }
  }

  async getUsers() {
    let res = [];
    if (this.allusersNoSelected.length > 0) {
      for (const val of this.allusersNoSelected) {
        res.push(val);
      }
    } else {
      res = await this.usersService.getUsers().toPromise().catch(err => this.handleError(err));
    }

    const data = [];

    this.allusersNoSelected = [];

    if (res != null && res.length > 0) {
      for (const element of res) {
        this.allusersNoSelected.push(element);

        element.fullName = element.firstName + ' ' + element.lastName;
        if (this.gts.includes('U' + element.userId)) {
          continue;
        } else {
          const rol = new Rol();
          rol.Id = element.userId;
          rol.Name = element.fullName;
          rol.Type = 'U';
          rol.isuser = true;
          rol.isgroup = false;
          data.push(rol);
        }
      }
      await this.getGroups(data);
    } else {
      await this.getGroups(data);
    }
  }

  onMoveToTarget(event: any) {
    this.mover = event.items;
    this.expiryDate = undefined;
    this.checked = false;
    this.displayExpiryDateModal = true;
  }

  onMoveToSource(event: any) {
    for (const val of event.items) {
      val.expired = false;
      val.location = 'S';
    }
  }

  confirm() {
    for (const val of this.mover) {
      const elm = this.usersGroupSelectedTarget.find(x => x.Id === val.Id && x.Type === val.Type);

      if (this.checked) {
        elm.expiry = new Date(2050, 11, 30);
        this.translate.get('GENERAL.NEVEREXPIRE').subscribe((resp) => {
          elm.expiryValue = resp;
        });
      } else {
        elm.expiry = this.expiryDate;
        elm.expiryValue = formatDate(elm.expiry, 'dd MMM yyy', 'en-US');
      }

      elm.location = 'T';
      if (new Date(elm.expiry) < new Date()) {
        elm.expired = true;
      } else {
        elm.expired = false;
      }

      this.usersGroupSelectedTarget.splice(this.usersGroupSelectedTarget.findIndex(x => x.Id === val.Id && x.Type === val.Type), 1, elm);
    }

    this.expiryDate = undefined;
    this.displayExpiryDateModal = false;
  }

  cancel() {
    for(const val of this.mover) {
      this.usersGroupSelectedTarget.splice(this.usersGroupSelectedTarget.findIndex(x => x.Id === val.Id && x.Type === val.Type), 1);
      this.usersGroupNoSelected.push(val);
      this.usersGroupNoSelected.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    this.expiryDate = undefined;
    this.displayExpiryDateModal = false;
  }

  onSave() {
    this.load=true;
    if(this.usersGroupSelectedTarget === undefined || this.usersGroupSelectedTarget === null || this.usersGroupSelectedTarget.length <= 0){
      this.rolesService.updateDeleteUserRole(this.selectedRole, this.usersGroupSelected, this.parentId).subscribe(async () => {
        const resp = await this.translate.get('GENERAL.SAVED').toPromise();
        this.notificationSvc.info('', resp, 2000);

        await this.loadManager(this.selectedRole);

        this.load = false;
      }, err => this.handleError(err));
    } else {
      this.rolesService.updateDeleteUserRole(this.selectedRole, this.usersGroupSelected, this.parentId).subscribe(() => {
          this.rolesService.createUserRole(this.selectedRole,this.usersGroupSelectedTarget, this.parentId).subscribe(async () => {
            
            const resp = await this.translate.get('GENERAL.SAVED').toPromise();
            this.notificationSvc.info('', resp, 2000);

            await this.loadManager(this.selectedRole);

            this.load = false;
          }, err => this.handleError(err));
      }, err => this.handleError(err));
    }
  }

  onCancel() {
    this.usersGroupSelectedTarget = [];
    this.usersGroupSelected = [];
    this.usersGroupNoSelected = [];
    for (const val of this.tempusersGroupSelectedTarget) {
      val.location = 'T';
      this.usersGroupSelectedTarget.push(val);
    }
    for (const val of this.tempusersGroupSelected) {
      this.usersGroupSelected.push(val);
    }
    for (const val of this.tempusersGroupNoSelected) {
      val.expired = false;
      this.usersGroupNoSelected.push(val);
    }

    this.resetSearch();
  }

  setTestExpiredDate() {
    this.expiryDate = new Date(new Date().setDate(new Date().getDate() - 1));
  }

  setTestNotDate() {
    this.expiryDate = new Date(new Date().setDate(new Date().getDate() + 1));
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

}
