import { Permission } from './../../Models/permission';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Urls } from 'src/app/Helpers/Urls';
import { Variables } from 'src/app/Helpers/variables';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  url = new Urls();
  apiUrl: string;
  orgId: number;

  constructor(
    private httpclient: HttpClient
  ) {
    this.apiUrl = this.url.apiUrl;
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
  }

  getPermissions(page, size, perm) {
    const v = new Variables();
    const val = localStorage.getItem('oi');
    const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);
    this.orgId = +oi;
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/permissions/' + page + '/' + size + '/' + this.orgId + '/' + perm + '/';
    return this.httpclient.get<any>(url)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  createPermission(perm: Permission) {
    // tslint:disable-next-line: max-line-length
    const url = this.apiUrl + '/api/permissions';
    return this.httpclient.post<any>(url, perm)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  deletePermission(perm: Permission) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = this.apiUrl + '/api/permissions/deletepermission';
    return this.httpclient.put<any>(url + '/' + 1, perm)
    .pipe(
      tap( // Log the result or error
        error => this.handleError
      )
    );
  }

  private handleError(error: any) {
    // console.error('error', error);
    throw error;
    return error;
  }

}
