import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { Group } from './../../Models/Group';
import { GroupsService } from './../../Services/groups/groups.service';
import { OrganisationsService } from './../../Services/organisations/organisations.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { group } from '@angular/animations';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { DataCache } from 'src/app/Helpers/data-cache';
import { TypeRoleGroup } from 'src/app/Models/role';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {
  GroupName: string;
  GroupDesc: string;

  isCreate: boolean;
  loading: boolean;

  organisations = [];
  organisation: any;

  OrgName: string;

  adgroups1 = [];
  adgroups2 = [];

  types = [];
  type: any;

  constructor(
    private orgService: OrganisationsService,
    private groupService: GroupsService,
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private location: Location,
    private http: HttpClient
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.SELECTORGANISATION').subscribe((resp: string) => {
      // this.organisations.push({label: resp + ' *', value: null});
    });
    this.getGroups();
    this.getOrganisation();
   }

  async ngOnInit() {
    this.loading = true;
    const none = await this.translate.get('GENERAL.NONE').toPromise();
    this.types.push({label: none, value: null});
    this.type = this.types[0].value;

    const data = await DataCache.grouptypedata$.toPromise();
    if (data !== undefined && data !== null && data.length > 0) {
      for (const val of data) {
        this.types.push({label: val.groupTypeName, value: val});
      }
    }
    this.loading = false;
  }

  getGroups() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    DataCache.groupdata$.subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          res[i].GroupName = res[i].groupName;
          this.adgroups1.push(res[i]);
        }
      }
      this.loading = false;
    });
  }

  getOrganisation() {
    this.loading = true;
    // tslint:disable-next-line: deprecation
    this.orgService.getOrganisationById().subscribe((res) => {
      if (res != null) {
        this.organisation = res;
        this.OrgName = res.organisationName;
      }
      this.loading = false;
    });
  }

  onCancel() {
    // this.router.navigateByUrl('/groups');
    this.location.back();
  }

  onCreate() {
    if (this.organisation === undefined || this.organisation === null) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.SELECTORGANISATION').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.GroupName === undefined || this.GroupName === null || this.GroupName.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERGROUPNAME').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }
    if (this.GroupDesc === undefined || this.GroupDesc === null || this.GroupDesc.trim() === '') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.ENTERGROUPDESCRIPTION').subscribe((resp: string) => {
        this.confirm(resp);
      });
      return;
    }

    const grp = new Group();
    grp.OrganisationId = this.organisation.organisationId;
    grp.GroupId = 0;
    grp.GroupName = this.GroupName;
    grp.GroupDescription = this.GroupDesc;
    if (this.type !== undefined && this.type !== null) {
      grp.GroupTypeId = this.type.groupTypeId;
    } else {
      grp.GroupTypeId = 0;
    }

    const rgroups = [];
    if (this.adgroups2 !== null && this.adgroups2.length > 0) {
      for (const val of this.adgroups2) {
        const rgroup = new TypeRoleGroup();
        rgroup.GroupId = val.groupId;
        rgroup.RoleName = 'Administrator';
        rgroup.RoleParentType = 'group';

        rgroups.push(rgroup);
      }
    }

    grp.RoleGroups = rgroups;

    this.loading = true;
    this.isCreate = true;
    // tslint:disable-next-line: deprecation
    this.groupService.postGroup(grp).subscribe((res) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.GROUPCREATED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
      });
      // this.router.navigateByUrl('/groups');
      this.location.back();
      this.isCreate = false;
      this.loading = false;

      DataCache.loadData(this.http);
    }, err => {
      this.handleError(err);
    });
  }

  confirm(msg) {
    this.confirmationService.confirm({
        message: msg,
        accept: () => {
            // Actual logic to perform a confirmation
        }
    });
}

  handleError(err) {
    this.isCreate = false;
    this.loading = false;
    if (err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_grp_org_id_grp_name_key"') {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.GROUPALREADYEXISTS').subscribe((resp: string) => {
        this.confirm(resp);
      });
    }

  }
}
