import { PermissionAdd, UserPermission } from './../../Models/user-permission';
import { GroupUsersService } from './../../Services/group-users/group-users.service';
import { Permission } from './../../Models/permission';
import { UserPermissionService } from './../../Services/user-permission/user-permission.service';
import { PermissionService } from './../../Services/permission/permission.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import * as CryptoJS from 'crypto-js';
import { GroupsService } from 'src/app/Services/groups/groups.service';
import { UsersService } from 'src/app/Services/users/users.service';
import { Variables } from 'src/app/Helpers/variables';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss']
})
export class PermissionComponent implements OnInit {

  permissions = [];
  permission: any;

  loadmore = false;
  isnoperms = false;
  isdelete = false;
  loading = false;
  isadd = false;
  isusers = false;

  page = 1;
  size = 100;

  yesText = 'Yes';
  noText = 'No';

  perm: any;
  desc: any;

  sourcegroups = [];
  targetgroups = [];
  groups = [];
  users = [];

  sperm = '';

  todelete = [];

  delete = false;

  tempTargetGroups = [];
  tempSourceGroups = [];

  isadminfull = false;
  isadminbasic = false;

  isadm = false;

  contentdiv = '';

  constructor(
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private cookieService: CookieService,
    private permService: PermissionService,
    private upermService: UserPermissionService,
    private groupService: GroupsService,
    private usersService: UsersService,
    private groupuserService: GroupUsersService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    this.translate.get('GENERAL.NO').subscribe((text: string) => {
      this.noText = text;
    });
    this.translate.get('GENERAL.YES').subscribe((text: string) => {
      this.yesText = text;
    });

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Permissions.Manage')
          || perm.includes('USM.Administration.Full')) {
          // this.ismanage = true;
      }

      if (perm.includes('USM.Administration.Full')) {
          this.isadm = true;
      }
    }
   }

  async ngOnInit() {
    this.onResize(null);
    await this.getPermissions();
  }

  async getPermissions() {
    this.loading = true;
    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;
    // const groups = localStorage.getItem('my-groups');
    const perms = await this.permService.getPermissions(this.page, this.size, ' ').toPromise()
    .catch(err => this.handleError(err));

    if (perms != null && perms.length > 0) {
      this.isnoperms = false;
      if (perms.length >= this.size) {
        this.loadmore = true;
      } else {
        this.loadmore = false;
      }
      for (const perm of perms) {
        perm.color = 'transparent';
        if (perm.perm.includes('.Administration.Full')) {
          if (groups != null && groups.includes('Administrators-Full_Group')) {
            // this.permissions.push(perm);
          }
        } else {
          this.permissions.push(perm);
        }
      }
    } else {
      this.loadmore = false;
      if (this.page === 1) {
        this.isnoperms = true;
      }
    }
    this.loading = false;
  }

  async getGroups() {
    this.loading = true;
    this.sourcegroups = [];
    this.tempSourceGroups = [];
    const res = await this.groupService.getGroups().toPromise()
    .catch(err => this.handleError(err));

    if (res != null && res.length > 0) {
      // tslint:disable-next-line: prefer-for-of
      for (const g of res) {
        if (this.groups.includes(g.groupId)) {
          continue;
        } else {
          this.sourcegroups.push(g);
          this.tempSourceGroups.push(g);
        }
      }

      this.sourcegroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
    }
    this.loading = false;
  }

  async save() {
    if (this.targetgroups.length === 0 && this.todelete.length === 0) {
      return;
    }

    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const mygroups = mg;
    if (mygroups != null && mygroups.includes('Administrators-Full_Group')) {
      // continue
    } else {
      for (const val of this.targetgroups) {
        if (this.isadminfull === true && val.groupName === 'Administrators-Full_Group') {
          this.translate.get('GENERAL.ONLYFULLUSERSCANMANAGE').subscribe((text: string) => {
            this.confirm(text, null, null);
          });
          return;
        }
        if (this.isadminbasic === true && val.groupName === 'Administrators-Basic_Group'
         && (mygroups == null || !mygroups.includes('Administrators-Basic_Group'))) {
          this.translate.get('GENERAL.ONLYBASICUSERSCANMANAGE').subscribe((text: string) => {
            this.confirm(text, null, null);
          });
          return;
        }
      }

      for (const val of this.todelete) {
        if (this.isadminfull === true && val.groupName === 'Administrators-Full_Group') {
          this.translate.get('GENERAL.ONLYFULLUSERSCANMANAGE').subscribe((text: string) => {
            this.confirm(text, null, null);
          });
          return;
        }
        if (this.isadminbasic === true && val.groupName === 'Administrators-Basic_Group'
         && (mygroups == null || !mygroups.includes('Administrators-Basic_Group'))) {
          this.translate.get('GENERAL.ONLYBASICUSERSCANMANAGE').subscribe((text: string) => {
            this.confirm(text, null, null);
          });
          return;
        }
      }
    }

    this.loading = true;
    const perms = new PermissionAdd();
    perms.ToDelete = this.todelete;
    perms.ToAdd = [];
    for (const perm of this.targetgroups) {
      const newg = new UserPermission();
      newg.OrgId = perm.organisationId;
      newg.Perm = this.permission.perm;
      newg.GroupId = perm.groupId;
      newg.FirstName = null;
      newg.LastName = null;
      newg.GroupName = null;
      newg.PermDesc = null;
      newg.PermId = 0;
      newg.UserId = 0;
      newg.UserPermId = 0;

      perms.ToAdd.push(newg);
    }

    const res = await this.upermService.createUserPermission(perms).toPromise()
    .catch(err => this.handleError(err));
    if (res != null && res === true)  {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.SAVED').subscribe((text: string) => {
          this.showSuccess(resp, text);
        });
      });
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.NOTSAVED').subscribe((text: string) => {
          this.showError(resp, text);
        });
      });
    }

    this.isadminfull = false;
    this.isadminbasic = false;
    await this.permClicked(this.permission);

    this.loading = false;
  }

  async showUsers(group) {
    await this.getUsersByGroupId(group);
    this.isusers = true;
  }

  cancelPerm() {
    this.isadminfull = false;
    this.isadminbasic = false;
    this.sourcegroups = [];
    this.targetgroups = [];
    for (const val of this.tempSourceGroups) {
      this.sourcegroups.push(val);
    }
    for (const val of this.tempTargetGroups) {
      this.targetgroups.push(val);
    }
    this.sourcegroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
    this.targetgroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
  }

  async getUsersByGroupId(grp) {
    this.loading = true;
    this.users = [];
    if (grp !== null) {
      const gus = await this.groupuserService.getUserGroupsById(grp.groupId).toPromise()
      .catch(err => this.handleError(err));
      if (gus != null && gus.length > 0) {
        for (const user of gus) {
          this.users.push(user);
        }
        this.users.sort((a, b) => a.firstName.localeCompare(b.firstName));
      }
    } else {
      //
    }
    this.loading = false;
  }

  async getUserPermissions() {
    this.loading = true;
    this.todelete = [];
    this.groups = [];
    this.targetgroups = [];
    this.tempTargetGroups = [];
    const perms = await this.upermService.getUserPermissions(0, 0, this.sperm).toPromise()
    .catch(err => this.handleError(err));

    if (perms != null && perms.length > 0) {
      for (const p of perms) {
        if (p.groupId > 0) {
          this.delete = true;
          this.groups.push(p.groupId);
          this.todelete.push(p);

          let g: any;
          g = [];
          g.groupName = p.groupName;
          g.groupId = p.groupId;
          g.groupDescription = '';
          g.organisationId = p.orgId;

          this.targetgroups.push(g);
          this.tempTargetGroups.push(g);
        }
        this.targetgroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
        if (this.targetgroups.length > 0) {
          this.delete = true;
        } else {
          this.delete = false;
        }
      }
    } else {
      this.delete = false;
    }
    this.loading = false;
  }

  async permClicked(perm) {
    this.loading = true;
    for (const p of this.permissions) {
      if (p.perm === perm.perm) {
        p.color = 'lightgray';
      } else {
        p.color = 'transparent';
      }
    }
    this.sperm = perm.perm;
    await this.getUserPermissions();
    await this.getGroups();
    this.permission = perm;
    this.loading = false;
  }

  async createPerm() {
    if (this.perm === undefined || this.perm === null || this.perm.trim() === '') {
      this.translate.get('GENERAL.ENTERPERMISSION').subscribe((text: string) => {
        this.confirm(text, null, null);
      });
    } else if (this.desc === undefined || this.desc === null || this.desc.trim() === '') {
      this.translate.get('GENERAL.ENTERPERMISSIONDESCRIPTION').subscribe((text: string) => {
        this.confirm(text, null, null);
      });
    } else {
      this.loading = true;
      const perm = new Permission();

      const v = new Variables();
      const val = localStorage.getItem('oi');
      const oi = CryptoJS.AES.decrypt(val, v.pass).toString(CryptoJS.enc.Utf8);

      perm.OrgId = +oi;
      // perm.OrgId = +localStorage.getItem('organisation-id');
      perm.Perm = this.perm;
      perm.Desc = this.desc;

      const id = await this.permService.createPermission(perm).toPromise()
      .catch(err => this.handleError(err));
      if (id !== null && id > 0) {
        this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
          this.translate.get('GENERAL.PERMISSIONCREATED').subscribe((text: string) => {
            this.showSuccess(resp, text);
          });
        });
      } else {
        this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
          this.translate.get('GENERAL.PERMISSIONNOTCREATED').subscribe((text: string) => {
            this.showError(resp, text);
          });
        });
      }
      this.cancel();
      this.page = 1;
      this.permissions = [];
      this.permission = null;
      await this.getPermissions();
      this.loading = false;
    }
  }

  add() {
    this.isadd = true;
  }

  cancel() {
    this.isadd = false;
    this.perm = null;
    this.desc = null;
  }

  showDelete() {
    this.isdelete = true;
    this.translate.get('GENERAL.DELETE').subscribe((text: string) => {
      this.confirm(text + ' ' + this.permission.perm + '?', 'deletepermission', null);
    });
  }

  async deletePerm() {
    this.loading = true;
    const res = await this.permService.deletePermission(this.permission).toPromise()
    .catch(err => this.handleError(err));
    if (res != null && res === true) {
      this.translate.get('GENERAL.SUCCESS').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONDELETED').subscribe((text: string) => {
          this.showSuccess(resp, text);
        });
      });
    } else {
      this.translate.get('GENERAL.ERROR').subscribe((resp: string) => {
        this.translate.get('GENERAL.PERMISSIONNOTDELETED').subscribe((text: string) => {
          this.showError(resp, text);
        });
      });
    }
    this.permission = null;
    this.permissions = [];
    this.page = 1;
    await this.getPermissions();
    this.loading = false;
  }

  async loadMore() {
    this.page += 1;
    await this.getPermissions();
  }

  move(event) {
    if (event !== undefined && event != null && event.items.length > 0) {
      for (const item of event.items) {
        if (item.groupName === 'Administrators-Full_Group') {
          this.isadminfull = true;
        }

        if (item.groupName === 'Administrators-Basic_Group') {
          this.isadminbasic = true;
        }
      }
    }
  }

  confirm(text, operation, value) {
    this.confirmationService.confirm({
        message: text,
        accept: async () => {
            // Accept logic
            if (operation === 'deletepermission') {
              await this.deletePerm();
            }
            this.isdelete = false;
        },
        reject: () => {
          // Reject logic
          this.isdelete = false;
        }
    });
  }

  showSuccess(title, message) {
    this.messageService.add({ key: 'tc', severity: 'success', summary: title, detail: message });
  }

  showInfo(title, message) {
      this.messageService.add({ key: 'tc', severity: 'info', summary: title, detail: message });
  }

  showWarn(title, message) {
      this.messageService.add({ key: 'tc', severity: 'warn', summary: title, detail: message });
  }

  showError(title, message) {
      this.messageService.add({ key: 'tc', severity: 'error', summary: title, detail: message });
  }

  onResize(event) {
    const menuheight = document.getElementById('menu').offsetHeight;
    const contentheight = window.innerHeight;

    if (menuheight !== undefined && menuheight !== null
      && contentheight !== undefined && contentheight !== null) {
      this.contentdiv = (+contentheight - menuheight - 50) + 'px';
    } else {
      this.contentdiv = '85vh';
    }
  }

  handleError(err) {
    this.isdelete = false;
    this.loading = false;
    if (err != null && err.error != null
       && err.error.MessageText === 'duplicate key value violates unique constraint "t_usm_perm_perm_org_id_key"') {
      this.translate.get('GENERAL.PERMISSIONALREADYEXISTS').subscribe((text: string) => {
        this.confirm(text, null, null);
      });
    } else {
      console.log(err);
    }
  }

}
