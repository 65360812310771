import { TypeRoleGroup, TypeRoleGroupInfo } from './role';

export class Department {
    public OrganisationId: number;
    public DepartmentId: number;
    public DepartmentName: string;
    public DepartmentDescription: string;
    public CostCenter: string;
    public DepartmentType: string;
    public RoleGroups: TypeRoleGroup[];
    public TypeRoleGroups: TypeRoleGroupInfo[];
}
