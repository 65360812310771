export class UserPermission {
    public OrgId: number;
        public PermId: number;
        public UserPermId: number;
        public Perm: string;
        public PermDesc: string;
        public UserId: number;
        public GroupId: number;
        public FirstName: string;
        public LastName: string;
        public GroupName: string;
}

export class PermissionAdd {
    public ToDelete: UserPermission[];
    public ToAdd: UserPermission[];
}
