export class Organisation {
    public OrganisationId: number;
    public OrganisationName: string;
    public OrganisationDescription: string;
    public AdminName: string;
    public AdminEmail: string;
    public AdminEmployeeId: number;
    public AdminPassword: string;
    public AdminPin: string;
}
