import { SynchronizeService } from './../../Services/synchronize/synchronize.service';
import { UsersService } from './../../Services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/Services/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { Variables } from 'src/app/Helpers/variables';
import { GroupsService } from 'src/app/Services/groups/groups.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users = [];
  options = [];
  selected: any;

  messages = 'notify';

  isLoadMore = true;
  page = 1;
  size = 100;
  load = false;

  ismanage = false;
  issync = false;

  loading = false;

  isadm = false;

  constructor(
    private userService: UsersService,
    private router: Router,
    public translate: TranslateService,
    private notificationSvc: NotificationService,
    private cookieService: CookieService,
    private groupService: GroupsService,
    private syncService: SynchronizeService
  ) {
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);

    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language');
    }
    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');

    const v = new Variables();
    const p = localStorage.getItem('ups');
    if (p != null) {
      const val = CryptoJS.AES.decrypt(p, v.pass).toString(CryptoJS.enc.Utf8);
      const perm = JSON.parse(val);
      if (perm.includes('USM.Users.Manage')
          || perm.includes('USM.Administration.Full')) {
          this.ismanage = true;
      }

      if (perm.includes('USM.Administration.Full')) {
          this.isadm = true;
      }
    }

    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;

    if (groups.includes('Administrators-Basic_Group') || groups.includes('Administrators-Full_Group')) {
      this.issync = true;
    }

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CHANGEPASSWORD').subscribe((resp3: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CHANGEPIN').subscribe((resp4: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
            this.options = [];
            if (this.ismanage === true) {
              this.options.push({ label: resp2, value: 'Edit' });
              this.options.push({ label: resp3, value: 'ChangePassword' });
              this.options.push({ label: resp4, value: 'ChangePin' });
            }
            this.options.push({ label: resp5, value: 'Details' });
          });
        });
      });
    });

    this.getUsers();
   }

  ngOnInit() {
    localStorage.removeItem('user');
  }

  gotoCreateUser() {
    this.router.navigateByUrl('createuser');
  }

  getUsers() {
    this.load = true;
    // tslint:disable-next-line: deprecation
    this.userService.getUsersPageSize(this.page, this.size).subscribe((res) => {
      if (res != null) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < res.length; i++) {
          // res[i].options = this.options;
          this.users.push(res[i]);
        }
        if (res.length < this.size) {
          this.isLoadMore = true;
        } else {
          this.isLoadMore = false;
        }
      } else {
        this.isLoadMore = true;
      }
      this.load = false;
    }, err => {
      this.handleError(err);
    });
  }

  async synchronize() {
    this.loading = true;

    const res = await this.syncService.synchronizeUsers().toPromise()
    .catch(err => this.handleError(err));

    if (res !== undefined && res !== null && res === true) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.USERSSYNCHRONIZED').subscribe((resp: string) => {
        this.notificationSvc.info('', resp, 2000);
      });
      this.page = 1;
      this.users = [];
      this.getUsers();
    }

    this.loading = false;
  }

  saveUser(event, user) {
    localStorage.setItem('user', JSON.stringify(user));
    if (event.value === 'Edit') {
      this.router.navigateByUrl('/users/' + user.userId + '/edit');
    } else if (event.value === 'ChangePassword') {
      this.router.navigateByUrl('/users/' + user.userId + '/changepassword');
    } else if (event.value === 'ChangePin') {
      this.router.navigateByUrl('/users/' + user.userId + '/changepin');
    } else if (event.value === 'Details') {
      this.router.navigateByUrl('/users/' + user.userId);
    }
  }

  async check(u) {
    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;
    this.loading = true;
    const uid = u.userId;
    let mygroups = '';
    const usergroups = await this.userService.getUserGroupsByGroupIdUserId(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    const gis = localStorage.getItem('gi');
    const gs = CryptoJS.AES.decrypt(gis, v.pass).toString(CryptoJS.enc.Utf8);
    const gids = JSON.parse(gs);

    let isedit = false;
    if (u.typeRoleGroups !== null && u.typeRoleGroups.length > 0) {
      for (const val of u.typeRoleGroups) {
        if (val.typeRoleGroup.roleName === 'Administrator' && gids.includes(val.typeRoleGroup.groupId)) {
          isedit = true;
        }
      }
    }

    this.options = [];
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.CHANGEPASSWORD').subscribe((resp3: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CHANGEPIN').subscribe((resp4: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
            if (isedit === true || this.isadm === true) {
              this.options.push({ label: resp2, value: 'Edit' });
              this.options.push({ label: resp3, value: 'ChangePassword' });
              this.options.push({ label: resp4, value: 'ChangePin' });
            }
          });
        });
      });
    });
    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
      this.options.push({ label: resp5, value: 'Details' });
    });

    for (const val of this.users) {
      if (val.userId === u.userId) {
        val.options = this.options;
      }
    }

    this.loading = false;
  }

  async check2(u) {
    const v = new Variables();
    const va = localStorage.getItem('mg');
    const mg = CryptoJS.AES.decrypt(va, v.pass).toString(CryptoJS.enc.Utf8);
    const groups = mg;
    this.loading = true;
    const uid = u.userId;
    let mygroups = '';
    const usergroups = await this.userService.getUserGroupsByGroupIdUserId(0, uid).toPromise()
                                                .catch(err => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch(err => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }

    this.options = [];
    let isfull = false;
    if (mygroups.includes('Administrators-Full_Group')) {
      isfull = true;
      if (groups != null && groups.includes('Administrators-Full_Group') && this.ismanage === true) {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CHANGEPASSWORD').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CHANGEPIN').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
                this.options.push({ label: resp2, value: 'Edit' });
                this.options.push({ label: resp3, value: 'ChangePassword' });
                this.options.push({ label: resp4, value: 'ChangePin' });
              });
            });
          });
        });
      }
    } else if (this.ismanage === true) {
      // tslint:disable-next-line: deprecation
      this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CHANGEPASSWORD').subscribe((resp3: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CHANGEPIN').subscribe((resp4: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
              this.options.push({ label: resp2, value: 'Edit' });
              this.options.push({ label: resp3, value: 'ChangePassword' });
              this.options.push({ label: resp4, value: 'ChangePin' });
            });
          });
        });
      });
    }

    if (mygroups.includes('Administrators-Basic_Group')) {
      if (groups != null && (groups.includes('Administrators-Basic_Group') || groups.includes('Administrators-Full_Group'))
       && this.ismanage === true) {
        this.options = [];
        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.EDIT').subscribe((resp2: string) => {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.CHANGEPASSWORD').subscribe((resp3: string) => {
            // tslint:disable-next-line: deprecation
            this.translate.get('GENERAL.CHANGEPIN').subscribe((resp4: string) => {
              // tslint:disable-next-line: deprecation
              this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
                this.options.push({ label: resp2, value: 'Edit' });
                this.options.push({ label: resp3, value: 'ChangePassword' });
                this.options.push({ label: resp4, value: 'ChangePin' });
              });
            });
          });
        });
      } else if (isfull === false) {
        this.options = [];
      }
    }

    // tslint:disable-next-line: deprecation
    this.translate.get('GENERAL.DETAILS').subscribe((resp5: string) => {
      this.options.push({ label: resp5, value: 'Details' });
    });

    for (const val of this.users) {
      if (val.userId === u.userId) {
        val.options = this.options;
      }
    }

    this.loading = false;
  }

  loadMore() {
    this.page += 1;
    this.getUsers();
  }

  handleError(err) {
    this.load = false;
    console.log(err);
  }

}
